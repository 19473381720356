import { css, html, LitElement } from 'lit';

const styles = css`
  :host {
    display: block;
    width: 100%;
    height: 100%;

    --ui-progress-border-radius: 16px;
    --ui-progress-border-stroke-width: 8px;
    --ui-progress-border-transition: stroke-dashoffset 0.5s linear;
    --_progress: 0;
    --_svg-opacity: 0;
    --_svg-transition: var(--ui-progress-border-transition);
  }

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    will-change: stroke-dashoffset; /* for better performance */
    stroke-dashoffset: var(--_progress);
    transition: var(--_svg-transition);
    opacity: var(--_svg-opacity);
  }

  path {
    fill: none;
    stroke-width: var(--ui-progress-border-stroke-width);
    stroke-dasharray: 1;
    stroke: lightblue;
  }
`;

export class UIProgressBorder extends LitElement {
  constructor() {
    super();

    /**
     * Value of the progress bar. From 0 to 100.
     * @type {number}
     */
    this.value = 0;

    this._offsetWidth = 0;
    this._offsetHeight = 0;
    this._isReset = false;

    this._resizeObserver = new ResizeObserver(() => {
      // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded.
      window.requestAnimationFrame(() => {
        // Get the new width and height.
        this._offsetWidth = this.offsetWidth;
        this._offsetHeight = this.offsetHeight;
      });
    });
    this._resizeObserver.observe(this);
  }

  static get styles() {
    return [styles];
  }

  static get properties() {
    return {
      value: { attribute: false },
      _offsetWidth: { state: true },
      _offsetHeight: { state: true },
    };
  }

  render() {
    if (!this._offsetHeight && !this._offsetWidth) return '';

    const renderProgress = () => {
      const offset = this._offsetWidth / 2;
      const strokeOffset = this._strokeWidth / 2;

      return html`
        <svg
          height=${this._offsetHeight}
          width=${this._offsetWidth}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="borderRight"
            d="M ${offset} ${this._offsetHeight - strokeOffset} h ${offset -
            strokeOffset -
            this._borderRadius} a ${this._borderRadius},${this
              ._borderRadius} 0 0 0 ${this._borderRadius},-${this
              ._borderRadius} V ${strokeOffset + this._borderRadius} a ${this
              ._borderRadius},${this._borderRadius} 0 0 0 -${this
              ._borderRadius},-${this._borderRadius} H ${offset}"
            pathLength="1"
            stroke-linejoin="round"
            stroke-linecap="round"
          />
          <path
            class="borderLeft"
            d="M ${offset} ${this._offsetHeight - strokeOffset} h -${offset -
            strokeOffset -
            this._borderRadius} a ${this._borderRadius},${this
              ._borderRadius} 0 0 1 -${this._borderRadius},-${this
              ._borderRadius} V ${strokeOffset + this._borderRadius} a ${this
              ._borderRadius},${this._borderRadius} 0 0 1 ${this
              ._borderRadius},-${this._borderRadius} H ${offset}"
            pathLength="1"
            stroke-linejoin="round"
            stroke-linecap="round"
          />
        </svg>
      `;
    };

    return html`
      <slot></slot>
      ${renderProgress()}
    `;
  }

  shouldUpdate(changedProperties) {
    // We don't want to update the component when the value is changed from undefined to 0.
    if (
      changedProperties.has('value') &&
      changedProperties.get('value') === undefined
    ) {
      return false;
    }

    return true;
  }

  willUpdate() {
    if (this._borderRadius && this._strokeWidth) return;

    this._borderRadius = Number(
      window
        .getComputedStyle(this)
        .getPropertyValue('--ui-progress-border-radius')
        .slice(0, -2),
    );

    this._strokeWidth = Number(
      window
        .getComputedStyle(this)
        .getPropertyValue('--ui-progress-border-stroke-width')
        .slice(0, -2),
    );
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      // If the value is 100 and it was 0, we don't want to animate the progress bar.
      if (this.value === 100 && changedProperties.get('value') === 0) {
        this._isDisabledAnimation = true;
        this.style.setProperty('--_svg-transition', 'none');
        this.style.setProperty('--_svg-opacity', 1);
      } else if (this._isDisabledAnimation) {
        this._isDisabledAnimation = false;
        this.style.setProperty(
          '--_svg-transition',
          'var(--ui-progress-border-transition)',
        );
      }

      // If start new progress state then reset the reset flag.
      if (this.value === 100) {
        // set reset flag to default value
        this._isReset = false;
      }

      // Update the progress bar.
      this.style.setProperty('--_progress', `${1 - this.value / 100}`);
    }
  }

  firstUpdated() {
    this.shadowRoot
      .querySelector('svg')
      .addEventListener('transitionend', () => {
        // If the value is 0 and it was not reset, we dispatch an event.
        // We don't want to dispatch the event when the progress bar is reset.
        // Progress bar is reset when a user clicks on the back button of the stage.
        if (this.value === 0 && !this._isReset) {
          this.style.setProperty('--_svg-opacity', 0);
          this.dispatchEvent(new Event('progress-finished'));
        }
      });
  }

  /**
   * Reset the progress bar.
   */
  reset() {
    this.style.setProperty('--_svg-opacity', 0);
    this.style.setProperty(
      '--_svg-transition',
      'var(--ui-progress-border-transition)',
    );
    this._isReset = true;
    this.value = 0;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._resizeObserver.disconnect();
  }
}

window.customElements.define('ui-progress-border', UIProgressBorder);
