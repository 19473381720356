import { html } from 'lit';
import '@material/web/iconbutton/icon-button';

import { WidgetBaseElement } from './widget-base-element.js';
import { WidgetEmotionStyles } from './styles/widget-emotion-styles.js';
import { WidgetEmotionIcons } from './icons/widget-emotion-icons.js';

import './ui-parts/ui-container.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-main-image.js';
import './ui-parts/ui-svg.js';
import './ui-parts/ui-title.js';
import './ui-parts/ui-textfield.js';

export class WidgetEmotion extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * `{"emotion": _selected_emo_, "text": _text_field_}`
     *
     * Where:
     * - `_selected_emo_`: `String` (neutral|smile|love|boring|disgust|anger)
     * - `_text_field_`: `String` (optional if `!isQuestionRequired`)
     * @type {Object}
     */
    this.answer = null;

    /**
     * URL path to image.
     */
    this.image = '';

    /**
     * User is required to fill at least 2 non-space characters to the question box.
     */
    this.isQuestionRequired = false;

    /**
     * Show question text box with a label contains text from customQuestion property.
     */
    this.showQuestion = false;

    /**
     * The label of a question text box.".
     */
    this.customQuestion = 'Proč? Napište první asociaci...';

    /**
     * Array of options to choose from, `{id: icon name}`.
     */
    this.values = [
      { id: 'love' },
      { id: 'smile' },
      { id: 'neutral' },
      { id: 'boring' },
      { id: 'anger' },
    ];

    this._isTextareaVisible = false;

    this.addEventListener('exit-stage', this._onExitStage);
  }

  static get styles() {
    return [super.styles, WidgetEmotionStyles];
  }

  static get properties() {
    return {
      image: {
        type: String,
      },

      values: {
        type: Array,
      },

      customQuestion: {
        type: String,
      },

      showQuestion: {
        type: Boolean,
      },

      isQuestionRequired: {
        type: Boolean,
      },

      _isTextareaVisible: {
        type: Boolean,
      },
    };
  }

  render() {
    const renderItem = item => html`
      <md-icon-button
        class="emotion"
        id="${item.id}"
        @click="${this._onButtonTap}"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          ${WidgetEmotionIcons[this._decodeSlug(item.id)]}
        </svg>
      </md-icon-button>
    `;

    return html`
      <ui-container id="container" isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <div class="items">
            ${this._encodeValues(this.values).map(renderItem)}
          </div>

          ${this._isTextareaVisible
            ? html`<ui-textfield
                id="textarea"
                type="textarea"
                label="${this.customQuestion}&nbsp;"
                @keyup="${this._onTextareaKeyUp}"
              ></ui-textfield>`
            : null}
        </ui-main>
      </ui-container>
    `;
  }

  _setAnswer() {
    if (this.showQuestion) {
      if (
        !this.isQuestionRequired ||
        (this.isQuestionRequired && this._textarea)
      ) {
        this.answer = {
          emotions: [this._decodeSlug(this._selectedEmotion)],
          text: this._textarea || '',
        };
      } else {
        this.answer = null;
      }
    } else {
      this._skipNextActionButton = true;
      this.answer = { emotions: [this._decodeSlug(this._selectedEmotion)] };
    }
  }

  async _onButtonTap(event) {
    this._selectedEmotion = event.currentTarget.id;
    this._setEmotions();

    if (this.showQuestion) {
      this._isTextareaVisible = true;

      // wait for textarea rendering
      await this.updateComplete;

      // wait for textarea animation
      setTimeout(() => {
        // scroll to qset-questions element bottom
        this.dispatchEvent(
          new CustomEvent('scroll-to-bottom', {
            bubbles: true,
            composed: true,
          }),
        );

        this.shadowRoot.getElementById('textarea').focus();
      }, 300);
    }

    this._setAnswer();
  }

  _onExitStage() {
    // Cleanup icons for gradient of SVG issue on ShadowDOM.
    const icons = this.shadowRoot.querySelectorAll('paper-icon-button');
    icons.forEach(icon => {
      icon.remove();
    });
  }

  _onTextareaKeyUp() {
    this._textarea = this.shadowRoot.getElementById('textarea').value;
    this._setAnswer();
  }

  _setEmotions() {
    const values = this._encodeValues(this.values);

    this.shadowRoot
      .querySelector(`#${this._selectedEmotion} #grayFilter`)
      .setAttribute('fill-opacity', 0);
    this.shadowRoot
      .querySelector(`#${this._selectedEmotion} #background`)
      .setAttribute('fill-opacity', 1);
    this.shadowRoot
      .querySelector(`#${this._selectedEmotion} #foreground`)
      ?.setAttribute('fill-opacity', 1);
    this.shadowRoot
      .querySelector(`#${this._selectedEmotion} g`)
      .setAttribute('fill', '#231f20');
    this.shadowRoot
      .querySelector(`#${this._selectedEmotion} g`)
      .setAttribute('stroke', '#231f20');

    for (let i = 0; i < values.length; i += 1) {
      const icon = values[i].id;
      if (icon !== this._selectedEmotion) {
        this.shadowRoot
          .querySelector(`#${icon} #grayFilter`)
          .setAttribute('fill-opacity', 0.05);
        this.shadowRoot
          .querySelector(`#${icon} #background`)
          .setAttribute('fill-opacity', 0.1);
        this.shadowRoot
          .querySelector(`#${icon} #foreground`)
          ?.setAttribute('fill-opacity', 0);
        this.shadowRoot
          .querySelector(`#${icon} g`)
          .setAttribute('fill', '#ccc');
        this.shadowRoot
          .querySelector(`#${icon} g`)
          .setAttribute('stroke', '#ccc');
      }
    }
  }

  _onWidgetResize() {
    this._setEmotionsSize();
  }

  _setEmotionsSize() {
    const size = Math.round(
      (this.shadowRoot.getElementById('container').offsetWidth - 80) / 5,
    );
    const emotions = this.shadowRoot.querySelectorAll('.emotion');

    emotions.forEach(emotion => {
      // eslint-disable-next-line no-param-reassign
      emotion.style.setProperty('--md-icon-button-icon-size', `${size}px`);
      // eslint-disable-next-line no-param-reassign
      emotion.style.setProperty(
        '--md-icon-button-state-layer-width',
        `${size}px`,
      );
      // eslint-disable-next-line no-param-reassign
      emotion.style.setProperty(
        '--md-icon-button-state-layer-height',
        `${size}px`,
      );
    });
  }
}

window.customElements.define('widget-emotion', WidgetEmotion);
