import { css } from 'lit';

export const WidgetEmotionStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  ui-main {
    overflow: hidden;
  }

  #description {
    height: var(--line-height);
  }

  md-icon-button {
    --md-icon-button-icon-size: 40px;
    --md-icon-button-state-layer-width: 40px;
    --md-icon-button-state-layer-height: 40px;

    --md-sys-color-primary: #ffdd67;
    --md-icon-button-hover-state-layer-color: var(--md-sys-color-primary);
    --md-icon-button-pressed-state-layer-color: var(--md-sys-color-primary);

    margin: 0 8px;
  }

  md-icon-button#anger {
    --md-sys-color-primary: #ff5252;
  }

  md-icon-button#disgust {
    --md-sys-color-primary: #75a843;
  }

  md-icon-button#love {
    --md-sys-color-primary: #ff5a79;
  }

  md-icon-button#neutral {
    --md-sys-color-primary: #d2d3cf;
  }

  ui-textfield {
    width: 80%;
    height: 70px;
    margin-top: 16px;
    margin-bottom: 16px;
    resize: none;
  }

  .items {
    display: flex;
  }

  /* Medium handset wide */
  @media screen and (min-width: 360px) {
    ui-textfield {
      width: 60%;
    }
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    iron-selector {
      margin-bottom: 6px;
    }

    ui-textfield {
      width: 70%;
    }
  }
`;
