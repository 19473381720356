import { css, html, LitElement } from 'lit';
import { mdDefaultRender } from '../utils/markdown.js';
import { throttleGate } from '../utils/utils.js';

/**
 * A title with a question and details.
 * @element ui-title
 *
 * Description:
 *  - The question and details are displayed in the center of the container.
 *  - The question font size is decreased if the question text height is greater than the container height and increased if the question text height is less than the container height.
 *  - The question font size is decreased until it reaches the minimum font size set in the CSS variable --_question-font-size-min.
 *  - The question font size is increased until it reaches the maximum font size set in the CSS variable --_question-font-size-max.
 *  - Minimal height of this component is set to avoid the container overflow. The minimal height is calculated based on the question text height, details text height, and the widget gutter.
 *  - The minimal height is removed if the question text height is less than the container height. This allows the container to grow. This solution is needed in widget-grid for ideal height of buttons.
 *  - The question text and details text are rendered using light markdown function mdDefaultRender().
 */

const style = css`
  :host {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: var(--widget-gutter);

    /* for ui-container with isFlex prop */
    flex: 1 1 auto;

    --_question-font-size: 24px;
    --_question-font-size-max: 24px;
    --_question-font-size-min: 14px;
  }

  :host([question='']) {
    display: none;
  }

  p {
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    position: relative;
    width: 100%;
    overflow: hidden;

    font-weight: 300;
    color: var(--tertiary-text-color);
  }

  .details {
    color: var(--secondary-text-color);
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 8px;
    font-weight: normal;
    text-align: center;
    position: relative;
    width: 100%;
  }

  .question {
    text-align: center;
    position: relative;
    width: 100%;

    /* default font size,
       fallback for browsers don't support clamp() */
    font-size: var(--_question-font-size);
    line-height: calc(var(--_question-font-size) + 6px);
    will-change: font-size, line-height;
    letter-spacing: -0.2px;
  }

  :host([question='']) .container {
    min-height: initial;
    padding: 0;
  }

  @media screen and (min-width: 600px) {
    :host {
      --_question-font-size-max: 28px;
    }

    .details {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

class UITitle extends LitElement {
  static get styles() {
    return style;
  }

  static get properties() {
    return {
      details: { type: String },
      question: { type: String, reflect: true },
      _question: { type: String },
      _details: { type: String },
    };
  }

  constructor() {
    super();
    this.details = '';
    this.question = '';

    this._throttleGateResize = throttleGate(10);

    this._resizeObserver = new ResizeObserver(() => {
      if (!this._throttleGateResize()) return;

      // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        this._setFontSize();
      });
    });
  }

  render() {
    return html`
      ${this.question || this.details
        ? html`
            <div class="container">
              ${this.question
                ? html`
                    <div class="question">
                      ${mdDefaultRender(this._question)}
                    </div>
                  `
                : ''}
              ${this.details
                ? html`
                    <div class="details">${mdDefaultRender(this._details)}</div>
                  `
                : ''}
            </div>
          `
        : ''}
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this._resizeObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._resizeObserver.disconnect();
  }

  firstUpdated() {
    this._containerElement = this.shadowRoot.querySelector('.container');
    this._questionElement = this.shadowRoot.querySelector('.question');
    this._detailsElement = this.shadowRoot.querySelector('.details');
    this._questionFontSize = Number(
      getComputedStyle(this)
        .getPropertyValue('--_question-font-size')
        .replace('px', ''),
    );
    this._questionFontSizeMax = Number(
      getComputedStyle(this)
        .getPropertyValue('--_question-font-size-max')
        .replace('px', ''),
    );
    this._questionFontSizeMin = Number(
      getComputedStyle(this)
        .getPropertyValue('--_question-font-size-min')
        .replace('px', ''),
    );
  }

  updated(changedProperties) {
    if (changedProperties.has('question') || changedProperties.has('details')) {
      this._setFontSize();
    }
  }

  _adjustFontSize(maxQuestionHeight, increase) {
    const fontSizeLimit = increase
      ? this._questionFontSizeMax
      : this._questionFontSizeMin;
    const fontSizeChange = increase ? 1 : -1;

    // increase or decrease font size until it reaches the limit
    if (
      (increase && this._questionFontSize >= fontSizeLimit) ||
      (!increase && this._questionFontSize <= fontSizeLimit)
    ) {
      this._adjustFontSizeIsRunning = false;

      if (!increase) {
        // get question text height, rounded up to avoid text overflow
        const questionHeight = Math.ceil(
          parseFloat(getComputedStyle(this._questionElement).height),
        );

        // set min height to avoid the container overflow
        // and remove max height to allow the container to grow
        if (questionHeight > maxQuestionHeight) {
          this._containerElement.style.removeProperty('max-height');
          this.style.minHeight = `${
            questionHeight + this._widgetGutter + this._detailsHeight
          }px`;
        }
      }

      return;
    }

    // set flag to avoid multiple calls to this function
    this._adjustFontSizeIsRunning = true;

    // increase or decrease font size
    this._questionFontSize += fontSizeChange;
    this.style.setProperty(
      '--_question-font-size',
      `${this._questionFontSize}px`,
    );

    // get question text height, rounded up to avoid text overflow
    const questionHeight = Math.ceil(
      parseFloat(getComputedStyle(this._questionElement).height),
    );

    if (
      (increase && questionHeight < maxQuestionHeight) ||
      (!increase && questionHeight >= maxQuestionHeight)
    ) {
      this._adjustFontSize(maxQuestionHeight, increase);
    } else {
      this._adjustFontSizeIsRunning = false;

      // decrease font size if question text height is greater than max height allowed for question text
      if (
        increase &&
        questionHeight > maxQuestionHeight &&
        this._questionFontSize > this._questionFontSizeMin
      ) {
        this._questionFontSize -= 1;
        this.style.setProperty(
          '--_question-font-size',
          `${this._questionFontSize}px`,
        );
      }
    }
  }

  async _setFontSize() {
    if (!this.question) return;

    // get widget gutter, the value is dynamic and can change during the component resize
    this._widgetGutter = Number(
      getComputedStyle(this)
        .getPropertyValue('--widget-gutter')
        .replace('px', ''),
    );

    // reset question text and details text to avoid font size calculation based on previous text
    this._question = '';
    this._details = '';
    this._containerElement.style.removeProperty('max-height');
    this.style.removeProperty('min-height');

    // wait for question text and details text to be inserted to DOM
    await this.updateComplete;

    // get container height and details height before insterting question text to DOM
    // trunced to avoid text overflow
    const containerHeight = Math.trunc(
      parseFloat(getComputedStyle(this._containerElement).height),
    );

    this._detailsHeight = 0;

    if (this.details) {
      this._details = this.details;

      // wait for details text to be inserted to DOM
      await this.updateComplete;

      // get details height, rounded up to avoid text overflow
      this._detailsHeight = Math.ceil(
        parseFloat(getComputedStyle(this._detailsElement).height),
      );
    }

    // compute max height for question text
    let maxQuestionHeight = containerHeight;
    if (this._detailsHeight) maxQuestionHeight -= this._detailsHeight;

    // set max height to avoid the container to grow when the question is inserted
    this._containerElement.style.maxHeight = `${containerHeight}px`;

    // set question text to compute its height
    this._question = this.question;

    // wait for question text to be inserted to DOM
    await this.updateComplete;

    // get question text height, rounded up to avoid text overflow
    const questionHeight = Math.ceil(
      parseFloat(getComputedStyle(this._questionElement).height),
    );

    // decrease font size if question text height is greater than max height allowed for question text
    if (questionHeight >= maxQuestionHeight && !this._adjustFontSizeIsRunning) {
      // decrease font size
      this._adjustFontSize(maxQuestionHeight);
      // increase font size if question text height is less than max height allowed for question text
    } else if (
      questionHeight < maxQuestionHeight &&
      !this._adjustFontSizeIsRunning
    ) {
      // increase font size
      this._adjustFontSize(maxQuestionHeight, true);
    }
  }
}

window.customElements.define('ui-title', UITitle);
