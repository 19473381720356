import { css, html, LitElement } from 'lit';

import { arrowBackIcon, redoIcon } from '../qset-icons.js';

const styles = css`
  :host {
    display: block;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: var(--action-button-padding) 0;
    height: var(--action-button-size);
  }

  :host([disabled]) {
    pointer-events: none;
  }

  :host([hidden]),
  :host([activeaction='none']) {
    display: none;
  }

  :host([activeaction='none'][showbackbutton]) {
    display: block;
  }

  :host([hasbackground][activeaction='next']),
  :host([hasbackground][activeaction='skip']),
  :host([hasbackground][activeaction='none'][showbackbutton]) {
    background-color: rgba(240, 240, 240, 0.9);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: var(--action-button-size);
    border-radius: 24px;
    color: var(--action-button-text-color);
    background-image: linear-gradient(
      360deg,
      var(--action-button-bottom-gradient-color) 0%,
      var(--action-button-top-gradient-color) 100%
    );
    border: 0;
    text-transform: uppercase;
    will-change: transform;
    cursor: pointer;
    user-select: none;
    animation: fadeIn 0.5s;
    margin: 0 auto;
    padding: 0 12px;
    box-sizing: border-box;
  }

  .button-label {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    /* For hide assistant bottom bar on Android Chrome */
    pointer-events: none;

    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .button-label.ok {
    font-size: 19px;
    line-height: 24px;
  }

  /* a little hack to keep the layout stable
   * keep the inactive item in the DOM, just hide and disable it */
  .backbutton {
    width: var(--action-button-size);
    height: var(--action-button-size);
    position: absolute;
    left: 8px;
    top: 8px;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    pointer-events: none;
    border-radius: 24px;
  }

  .backbutton[active] {
    cursor: pointer;
    pointer-events: all;
    will-change: transform, opacity;
    animation: fadeInWithOpacity 0.5s forwards;
    /* wait for animation of a stage card */
    animation-delay: 0.5s;
  }

  .backbutton svg {
    margin-top: calc((var(--action-button-size) - 24px) / 2);
    margin-left: calc((var(--action-button-size) - 24px) / 2);
  }

  @keyframes fadeIn {
    from {
      transform: scale(0);
    }
    to {
      transform: none;
    }
  }

  @keyframes fadeInWithOpacity {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  @media screen and (min-width: 600px) {
    :host {
      position: relative;
    }

    .backbutton {
      left: calc(50% - var(--card-width) / 2);
    }
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) and (min-height: 480px) {
    :host {
      margin-bottom: 0;
    }

    .button {
      width: 200px;
      margin: 0 auto;
    }

    .button-label {
      font-size: 18px;
    }
  }

  /* Desktop 720p */
  @media screen and (min-width: 600px) and (max-height: 700px) {
    .button-label.ok {
      line-height: 21px;
    }
  }
`;

class StageButtons extends LitElement {
  constructor() {
    super();

    /**
     * Active action button type. Values are 'next', 'skip', 'none'.
     */
    this.activeAction = 'none';

    /**
     * Label of the next button.
     */
    this.nextButtonLabel = 'ok';

    /**
     * Show back button.
     */
    this.showBackButton = false;

    /**
     * If true, show background of buttons.
     */
    this.hasBackground = false;

    /**
     * If true, disable buttons.
     */
    this.disabled = false;
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      activeAction: {
        type: String,
        reflect: true,
      },

      nextButtonLabel: {
        type: String,
      },

      showBackButton: {
        type: Boolean,
        reflect: true,
      },

      hasBackground: {
        type: Boolean,
        reflect: true,
      },

      disabled: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  render() {
    const renderNextButton = () => html`
      <div
        class="button"
        id="next"
        title="Potvrdit"
        @click="${this._onNextButtonClick}"
      >
        <div class="button-label ${this.nextButtonLabel}" id="buttonLabel">
          ${this.nextButtonLabel}
        </div>
      </div>
    `;

    const renderSkipButton = () => html`
      <div
        class="button"
        id="skip"
        title="Přeskočit otázku"
        @click="${this._onSkipButtonClick}"
      >
        ${redoIcon}
      </div>
    `;

    const renderBackButton = () => html`
      <div
        class="backbutton"
        @click="${this._onBackButtonClick}"
        ?active="${this.showBackButton}"
      >
        ${arrowBackIcon}
      </div>
    `;

    // a little hack to keep the layout stable
    // keep the inactive back button in the DOM, just hide and disable it
    return html`
      ${renderBackButton()}
      ${this.activeAction === 'next' ? renderNextButton() : ''}
      ${this.activeAction === 'skip' ? renderSkipButton() : ''}
    `;
  }

  /**
   * Set focus to active button.
   */
  focusActiveButton() {
    this.shadowRoot.getElementById(this.activeAction)?.focus();
  }

  _onBackButtonClick() {
    this.dispatchEvent(new CustomEvent('back-button-click'));
  }

  _onNextButtonClick() {
    this.dispatchEvent(new CustomEvent('next-button-click'));
  }

  _onSkipButtonClick() {
    this.dispatchEvent(new CustomEvent('skip-button-click'));
  }
}

window.customElements.define('stage-buttons', StageButtons);
