import { css } from 'lit';

export const WidgetScaleStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;

    --item-size: 24px;
    --item-point-margin: 4px;
    --item-color: var(--secondary-text-color);
    --selected-item-color: var(--primary-color);
  }

  .item {
    width: var(--item-size);
    height: var(--item-size);
    border: 1px dashed var(--item-color);
    border-radius: 50%;
    padding: 1px;
    margin: 0 4px;
  }

  .item.ui-selected {
    border: 2px solid var(--selected-item-color);
    padding: 0;
  }

  .item.ui-selected .item-point {
    width: calc(var(--item-size) - var(--item-point-margin) * 2);
    height: calc(var(--item-size) - var(--item-point-margin) * 2);
    margin: var(--item-point-margin);
    background-color: var(--selected-item-color);
    border-radius: 50%;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .items.steps5 .item:nth-of-type(1),
  .items.steps5 .item:nth-of-type(5) {
    --item-size: 48px;
    --item-point-margin: 8px;
  }

  .items.steps5 .item:nth-of-type(2),
  .items.steps5 .item:nth-of-type(4) {
    --item-size: 32px;
    --item-point-margin: 6px;
  }

  .items.steps7 .item:nth-of-type(1),
  .items.steps7 .item:nth-of-type(7) {
    --item-size: 36px;
    --item-point-margin: 6px;
  }

  .items.steps7 .item:nth-of-type(2),
  .items.steps7 .item:nth-of-type(6) {
    --item-size: 32px;
    --item-point-margin: 6px;
  }

  .items.steps7 .item:nth-of-type(3),
  .items.steps7 .item:nth-of-type(5) {
    --item-size: 28px;
    --item-point-margin: 6px;
  }

  .items.steps11 > .item {
    --item-size: 16px;
    --item-point-margin: 3px;
  }

  .items.steps5 .item.variant-red-green:nth-of-type(1),
  .items.steps5 .item.variant-red-green:nth-of-type(2) {
    --item-color: var(--red-color);
    --selected-item-color: var(--red-color);
  }

  .items.steps5 .item.variant-red-green:nth-of-type(4),
  .items.steps5 .item.variant-red-green:nth-of-type(5) {
    --item-color: var(--green-color);
    --selected-item-color: var(--green-color);
  }

  .items.steps5 .item.variant-red-green:nth-of-type(3) {
    --selected-item-color: var(--item-color);
  }

  .items.steps7 .item.variant-red-green:nth-of-type(1),
  .items.steps7 .item.variant-red-green:nth-of-type(2),
  .items.steps7 .item.variant-red-green:nth-of-type(3) {
    --item-color: var(--red-color);
    --selected-item-color: var(--red-color);
  }

  .items.steps7 .item.variant-red-green:nth-of-type(5),
  .items.steps7 .item.variant-red-green:nth-of-type(6),
  .items.steps7 .item.variant-red-green:nth-of-type(7) {
    --item-color: var(--green-color);
    --selected-item-color: var(--green-color);
  }

  .items.steps7 .item.variant-red-green:nth-of-type(4) {
    --selected-item-color: var(--item-color);
  }

  .labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    padding: 0 0 2px 0;
    box-sizing: border-box;
    line-height: 18px;
  }

  .left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% / 3);
    height: 36px;
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: calc(100% / 3);
    height: 36px;
    text-align: right;
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 8px;
    width: calc(100% / 3);
    height: 36px;
  }

  ui-main-image {
    --ui-main-image-padding-bottom: 8px;
  }

  /* Medium handset wide */
  @media screen and (min-width: 360px) {
    .items.steps5 > .item {
      margin: 0 8px;
    }

    .items.steps7 > .item {
      margin: 0 7px;
    }

    .items.steps11 > .item {
      margin: 0 6px;
    }
  }

  @media screen and (min-width: 512px) {
    .items.steps11 > .item {
      margin: 0 8px;
    }
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    .items.steps5 > .item {
      margin: 0 11px;
    }

    .items.steps7 > .item {
      margin: 0 8px;
    }

    .items.steps7 .item:nth-of-type(1),
    .items.steps7 .item:nth-of-type(7) {
      --item-size: 48px;
    }

    .items.steps7 .item:nth-of-type(2),
    .items.steps7 .item:nth-of-type(6) {
      --item-size: 40px;
    }

    .items.steps7 .item:nth-of-type(3),
    .items.steps7 .item:nth-of-type(5) {
      --item-size: 32px;
    }

    .labels {
      padding: 0 0 2px 0;
    }
  }

  @media screen and (min-width: 600px) and (min-height: 700px) {
    .items.steps5 > .item {
      margin: 0 18px;
    }

    .labels {
      padding: 0 14px 2px 14px;
    }
  }
`;
