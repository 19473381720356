import { css, html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

/**
 * @element ui-image
 * @attr {String} src - The image source.
 * @attr {String} srcset - The image source set.
 * @attr {String} alt - The image alt text.
 * @attr {String} sizing - Sets a sizing option for the image.
 * Valid values are `contain` (full aspect ratio of the image is contained within the element and
 * letterboxed) or `cover` (image is cropped in order to fully cover the bounds of the element).
 * @fires ui-image-waiting-for-media - Fires when the image is initialized with the src as the detail.
 * @fires ui-image-media-loaded - Fires when the image is loaded with the src as the detail.
 * @cssprop --ui-image-width - The width of the image.
 * @cssprop --ui-image-height - The height of the image.
 */
class UIImage extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        position: relative;
        --_image-fit: contain;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: var(--_image-fit);
        width: var(--ui-image-width, 100%);
        height: var(--ui-image-height, 100%);
        border-radius: inherit;
      }
    `;
  }

  static get properties() {
    return {
      src: { type: String },
      srcset: { type: String },
      alt: { type: String },
    };
  }

  render() {
    return html`<img
      src="${this.src}"
      srcset="${ifDefined(this.srcset)}"
      alt="${ifDefined(this.alt)}"
      @load="${this._onLoad}"
    />`;
  }

  updated(changedProperties) {
    if (changedProperties.has('src')) {
      this._loadStartTime = +new Date();
    }
  }

  firstUpdated() {
    this.setAttribute('role', 'presentation');

    if (this.hasAttribute('sizing')) {
      this.style.setProperty('--_image-fit', this.getAttribute('sizing'));
    }

    this.dispatchEvent(
      new CustomEvent('ui-image-waiting-for-media', {
        detail: { src: this.src },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _onLoad() {
    this.dispatchEvent(
      new CustomEvent('ui-image-media-loaded', {
        detail: { src: this.src, loadTime: +new Date() - this._loadStartTime },
        bubbles: true,
        composed: true,
      }),
    );
  }
}

customElements.define('ui-image', UIImage);
