import { css } from 'lit';
import { MdTextButton } from '@material/web/button/text-button';

class UIButton extends MdTextButton {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 48px;
          padding: 0 8px;
          text-wrap: wrap; /* default value is 'nowrap' */

          --md-text-button-label-text-weight: 400;
          --md-focus-ring-color: transparent;
        }

        .button {
          display: inherit;
        }

        /* touch area has min height of 48px in all places */
        .touch {
          height: inherit;
        }

        .button:not(:disabled) {
          color: inherit;
        }

        :host(:focus-within) .button {
          color: inherit;
        }

        :host(:hover) .button {
          color: inherit;
        }

        :host(:active) .button {
          color: inherit;
        }
      `,
    ];
  }
}

window.customElements.define('ui-button', UIButton);
