import { css, LitElement } from 'lit';
import { WidgetBaseMixin } from './mixins/widget-base-mixin.js';

const styles = css`
  :host {
    width: 100%;
  }

  :host([hidden]) {
    display: none;
  }

  * {
    outline-width: 0;
  }

  [hidden] {
    display: none !important;
  }

  a {
    color: var(--accent-color);
    cursor: pointer;
    text-decoration: none;
    text-shadow: 0 0.5px 0 rgba(0, 0, 0, 0);
    font-size: var(--button-font-size);
    outline-width: 0;
  }

  /* Inline Flexbox container for vertical center <iron-icon> in text. */
  span[flex] {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  b,
  strong {
    font-weight: 500;
  }

  .spacer {
    display: flex;
  }

  .chip {
    height: 40px;
    border: 1px dashed var(--divider-color);
    border-radius: 88px;
    background-color: transparent;
    color: var(--secondary-text-color);
    font-weight: 400;
    font-size: 16px;
    line-height: var(--line-height);
    padding: 1px;
    box-sizing: border-box;
  }

  .chip.ui-selected {
    background-color: var(--light-primary-color);
    color: var(--text-light-primary-color);
    border: 2px solid var(--primary-color);
    padding: 0;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) and (min-height: 480px) {
    .chip {
      height: 48px;
    }
  }
`;

export class WidgetBaseElement extends WidgetBaseMixin(LitElement) {
  static get styles() {
    return styles;
  }
}
