import { css, html } from 'lit';

import { WidgetBaseElement } from './widget-base-element.js';
import { onIOS } from './utils/browser.js';

import './ui-parts/ui-container.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-title.js';

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  video {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: var(--border-radius);
  }
`;

/**
  ## Display one video

  Useful for some static messages that do not require interaction.
  Use question text to display formatted text.
*/
export class WidgetVideo extends WidgetBaseElement {
  constructor() {
    super();

    this.addEventListener('enter-stage', this._onEnterStage);
    this.addEventListener('exit-stage', this._onExitStage);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * URL of the video in MP4 format to display.
       */
      video: {
        type: String,
      },
    };
  }

  render() {
    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main>
          <video loop muted playsinline @loadeddata="${this._onLoadedData}">
            <source
              src="${this._computeAssetPath(this.video)}"
              type="video/mp4"
            />
          </video>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated() {
    super.firstUpdated();

    this._player = this.shadowRoot.querySelector('video');

    // fire init event here, so we can preload the video on second a card on background
    this.dispatchEvent(
      new CustomEvent('waiting-for-media', {
        detail: { src: this.video },
      }),
    );

    // Safari on iOS needs load video manually
    if (onIOS && this._player.canPlayType('application/vnd.apple.mpegurl')) {
      this._player.load();
    }
  }

  _onEnterStage() {
    this._player.play();

    // show action button
    this.answer = ['watched'];
  }

  _onExitStage() {
    this._player.pause();
  }

  // for video like GIF we need to fire event when whole video is loaded
  _onLoadedData() {
    this.dispatchEvent(
      new CustomEvent('media-loaded', {
        detail: { src: this.video },
      }),
    );
  }
}

window.customElements.define('widget-video', WidgetVideo);
