import { css, html } from 'lit';

import { WidgetBaseElement } from './widget-base-element.js';
import { customEvent } from './utils/utils.js';

import './ui-parts/ui-container.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-title.js';
import './ui-parts/ui-video-cloudflare.js';

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    height: 100%;
  }

  ui-main {
    display: block;
  }

  ui-title {
    padding: var(--widget-gutter) var(--widget-gutter) 0 var(--widget-gutter);
    box-sizing: border-box;
  }
`;

/**
  Play a video from Cloudflare
*/
export class WidgetVideoCloudflare extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * If `autoplay` is `true`, tells the browser to immediately start downloading the video and play it as soon as it can.
     */
    this.autoplay = false;

    /**
     * If `lightBox` is `true`, display the video full screen when clicked.
     */
    this.lightBox = false;

    /**
     * Cloudflare video id.
     */
    this.videoId = '';

    /**
     * Start of video, in seconds.
     */
    this.start = 0;

    /**
     * End of video, in seconds.
     */
    this.end = 0;

    /**
     * The length of video segment in seconds.
     * The segment is used for play a video at a random start time.
     * Default value is 0, this mean disabled randomization.
     */
    this.randomSegmentLength = 0;

    /**
     * Square size for video with aspect ration 1:1 or 9:16.
     */
    this.squarePlayer = false;

    this.addEventListener('enter-stage', this._onStageEntered);
    this.addEventListener('exit-stage', this._onStageExited);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      videoId: {
        type: String,
      },

      lightBox: {
        type: Boolean,
      },

      randomSegmentLength: {
        type: Number,
      },

      start: {
        type: Number,
      },

      end: {
        type: Number,
      },

      autoplay: {
        type: Boolean,
      },

      squarePlayer: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <ui-container isFlex>
        ${this.question
          ? html`
              <ui-title
                .question="${this.question}"
                .details="${this.details}"
              ></ui-title>
            `
          : ``}

        <ui-main>
          <ui-video-cloudflare
            id="player"
            .videoId="${this.videoId}"
            .lightBox="${this.lightBox}"
            .randomSegmentLength="${this.randomSegmentLength}"
            .start="${this.start}"
            .end="${this.end}"
            .autoplay="${this.autoplay}"
            .parent="${{ name: this.localName, id: this.id }}"
            .squarePlayer="${this.squarePlayer}"
            .cloudFlareBaseUrl="${this._cloudFlareBaseUrl}"
            @ended="${this._onVideoEnded}"
            @canplay="${() =>
              this.dispatchEvent(
                new CustomEvent('media-loaded', {
                  detail: { src: this.videoId },
                }),
              )}"
          ></ui-video-cloudflare>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated() {
    super.firstUpdated();

    this._player = this.shadowRoot.getElementById('player');

    // init the player here, so we can preload the video on second a card on background
    this._player.init();

    this.dispatchEvent(
      new CustomEvent('waiting-for-media', {
        detail: { src: this.videoId },
      }),
    );
  }

  _onVideoEnded(event) {
    if (this.lightBox) this._skipNextActionButton = true;

    this.answer = event.detail;
  }

  _onStageExited() {
    /**
     * if the widget goes off stage for any reason, we don't want the video
     * to play any more...
     */
    if (this._player) {
      this._player.pause();
      this._player.cleanDOM();
    }

    window
      .matchMedia('(orientation: portrait)')
      .removeEventListener('change', this._handleOrientationChangeBind);
  }

  async _onStageEntered() {
    // reset the answer for back navigation to previous widget
    this.answer = null;

    this._handleOrientationChange = media => {
      this.dispatchEvent(
        customEvent(
          'log',
          {
            ts: +new Date(),
            type: 'debug',
            name: 'video orientation change',
            value: media.matches ? 'portrait' : 'landscape',
            category: 'change',
            source: this.videoId,
            widget: { id: this.id, name: this.localName },
          },
          true,
        ),
      );
    };
    this._handleOrientationChangeBind =
      this._handleOrientationChange.bind(this);

    await this.updateComplete;

    window
      .matchMedia('(orientation: portrait)')
      .addEventListener('change', this._handleOrientationChangeBind);

    await this._player.updateComplete;

    // reset the player for displaying the video again, if the user goes back to this widget
    this._player.reset();

    if (this.autoplay) {
      this._player.play();
    }
  }

  _onWidgetResize() {
    if (this._player) this._player.setPlayerSize();
  }
}

window.customElements.define('widget-video-cloudflare', WidgetVideoCloudflare);
