// Mixins with JavaScript Classes
// https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/

/**
 * ## Multichoice widget on top of `ui-multi-selector`
 *
 * Use `WidgetMultiChoiceMixin` to implement multiple choice widgets
 * with `ui-multi-selector`.
 */
export const WidgetMultiChoiceMixin = superclass =>
  class extends superclass {
    constructor() {
      super();

      /**
       * Allow multiple selections.
       */
      this.multi = false;

      /**
       * Minimum selected items for the answer to be valid.
       * 0 means no minimum, which is default.
       * (to continue with no assignment use `skipTimeout`).
       */
      this.minSelectedItems = 0;

      /**
       * Selected values.
       */
      this._selection = [];
    }

    static get properties() {
      return {
        multi: {
          type: Boolean,
        },

        _selection: {
          type: Array,
        },
      };
    }

    updated(changedProperties) {
      if (super.updated) super.updated(changedProperties);

      if (changedProperties.has('_selection')) this._selectionChanged();
    }

    _selectionChanged() {
      // decode slugs from Base58 to original values
      const selection = this._selection.map(i => this._decodeSlug(i));

      if (this.multi) {
        if (this.minSelectedItems <= selection.length) {
          this.answer = selection;
        } else {
          this.answer = [];
        }

        return;
      }

      // rank question is answered only when all the choices
      // are ranked
      if (this.rank) {
        if (selection.length === this.values.length) {
          this.answer = selection;
        } else {
          this.answer = [];
        }

        return;
      }

      // otherwise we're in single select mode
      // ignore empty selection after double click on selected item on touch screen
      if (selection.length === 0) return;

      // no waiting for click on next button
      this._skipNextActionButton = true;
      this.answer = selection;
    }
  };
