import { css, html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import '@material/web/iconbutton/icon-button';

import { WidgetBaseElement } from './widget-base-element.js';
import { WidgetMultiChoiceMixin } from './mixins/widget-multi-choice-mixin.js';
import { RandomizeMixin } from './mixins/randomize-mixin.js';
import { mdDefaultRender } from './utils/markdown.js';
import { customEvent } from './utils/utils.js';

import './ui-parts/ui-container.js';
import './ui-parts/ui-chip.js';
import './ui-parts/ui-svg.js';
import './ui-parts/ui-image.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-main-image.js';
import './ui-parts/ui-title.js';
import './ui-parts/ui-multi-selector.js';
import './ui-parts/ui-textfield.js';

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
    overflow-y: auto;
  }

  .input {
    width: 100%;
    margin: 0 auto;

    --md-sys-color-primary: var(--tertiary-text-color);
    --md-filled-field-container-color: var(--fill-color);
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .next-button {
    position: absolute;
    top: 9px;
    right: 9px;
    line-height: 20px;
    font-size: 15px;

    --md-sys-color-primary: var(--tertiary-text-color);
  }

  :host([rtl]) .next-button {
    right: auto;
    left: 9px;
  }

  .chip {
    min-width: 40px;
  }

  .chip .image {
    display: block;
    width: 28px;
    height: 28px;
  }

  .chip .icon {
    display: block;
    width: 28px;
    height: 28px;
    fill: var(--primary-text-color);
  }

  .chip[is-icon] .chip-label,
  .chip[is-image] .chip-label {
    margin: 0 10px 0 -2px;
  }

  .chip.ui-selected ui-svg {
    fill: var(--accent-color);
  }

  .items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
    margin-bottom: 8px;
    width: 100%;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    .chip[is-icon] .chip-label,
    .chip[is-image] .chip-label {
      margin: 0 12px 0 -4px;
    }
  }
`;

/**
  ## Input freeform text with chips

  Text input with optional type-ahead. Can generate an array of inputs.
*/
export class WidgetInputChips extends WidgetMultiChoiceMixin(
  RandomizeMixin(WidgetBaseElement),
) {
  constructor() {
    super();
    this.validate = {};
    this.values = [];
    this._chips = [];
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * Image asset ID.
       */
      image: {
        type: String,
        reflect: true,
      },

      /**
       * `Array` of `String`, in the order of appearance.
       */
      answer: {
        type: Array,
      },

      /**
       * Hint to display in the first input of an array
       * (does not behave like real value, eg disappears on focus).
       *
       * If this is an array (`["hint1", "hint2"]`), use the hints
       * one by one in array of inputs (do not recycle).
       */
      hint: {
        type: String,
      },

      /**
       * Validate options object:
       * `{"rule": "regex", message: "", _validator_args_...}`
       * example: `{"rule": "regex",
       *   "message": "Jen cisla a mezery", "pattern": "^[0-9 ]+$"}`
       */
      validate: {
        type: Object,
      },

      /**
       * Primary source for chips.
       *
       * `{id: slug, label: text to display}`.
       */
      values: {
        type: Array,
      },

      _chips: {
        type: Array,
      },
    };
  }

  render() {
    const renderInput = () => html`
      <ui-textfield
        class="input"
        id="input"
        label="${this.hint}"
        pattern="${this._ifelseEq(
          this.validate.rule,
          'regex',
          this.validate.pattern,
          '',
        )}"
        @keyup="${this._onInputKeyUp}"
        @input="${this._onInputChange}"
        @invalid="${this._onInputInvalid}"
      ></ui-textfield>
    `;

    const renderItem = item => html`
      <ui-chip
        name="${item.id}"
        raised
        class="chip ${item.id}"
        is-icon="${ifDefined(item.icon)}"
        is-image="${ifDefined(item.image)}"
      >
        ${item.image
          ? html`
              <ui-image
                class="image"
                slot="label"
                .src="${this._computeItemImagePath(item.image)}"
              ></ui-image>
            `
          : ''}
        ${item.icon
          ? html`
              <ui-svg
                class="icon"
                .src="${this._computeAssetPath(item.icon)}"
                slot="label"
              ></ui-svg>
            `
          : ''}
        ${item.label
          ? html`
              <div class="chip-label" slot="label">
                ${mdDefaultRender(item.label)}
              </div>
            `
          : ''}
      </ui-chip>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <ui-multi-selector
            class="items"
            id="items"
            ?multi="${this.multi}"
            @selection-changed="${e => {
              this._selection = e.detail.selection;
            }}"
          >
            ${this._encodeValues(this._chips).map(renderItem)}
          </ui-multi-selector>

          <div class="input-container" id="inputContainer">
            ${renderInput()}

            <md-icon-button
              class="next-button"
              id="nextButton"
              @click="${this._onAddChipClick}"
              hidden
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                />
              </svg>
            </md-icon-button>
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated(changedProperties) {
    if (super.firstUpdated) super.firstUpdated(changedProperties);

    this._input = this.shadowRoot.getElementById('input');
    this._items = this.shadowRoot.getElementById('items');
    this._nextButton = this.shadowRoot.getElementById('nextButton');
  }

  updated(changedProperties) {
    if (super.updated) super.updated(changedProperties);

    if (changedProperties.has('values')) {
      this._chips = this.values.map(obj => ({
        id: obj.id,
        label: obj.label,
        icon: obj.icon,
        image: obj.image,
        closed: true,
      }));
    }
  }

  _onAddChipClick() {
    this._addChip();
    this._input.focus();
  }

  _onInputKeyUp(event) {
    if (!this._input.reportValidity()) return;

    const value = event.target.value.trim();

    if (event.key === 'Enter' && this._checkCorrectChip()) {
      this._addChip();
    } else if (value !== '') {
      this._nextButton.hidden = !this._checkCorrectChip();
      this.dispatchEvent(customEvent('hide-action-button', null, true));
    } else {
      this._nextButton.hidden = true;
    }
  }

  _onInputChange(event) {
    event.target.setCustomValidity('');
  }

  _onInputInvalid(event) {
    event.target.setCustomValidity(this.validate.message);
  }

  async _addChip() {
    const value = this._input.value.trim();
    const chipId = `custom_${value}`;
    this._chips.push({ id: chipId, label: value });
    this.requestUpdate();

    await this.updateComplete;

    this._items.unselectItem('none');
    this._items.selectItem(this._encodeSlug(chipId));
    this._input.value = '';
    this._nextButton.hidden = true;

    // scroll to the widget bottom
    this._items.scrollTo({
      top: this._items.scrollHeight - this._items.offsetHeight,
      left: 0,
    });

    // show next action button after "hide-action-button" event
    this.dispatchEvent(customEvent('show-next-action-button', null, true));
  }

  _checkCorrectChip() {
    if (this._input.value.trim() === '') return false;

    return !this._chips.find(item => item.label === this._input.value.trim());
  }

  _computeItemImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf('/') !== -1) return this._computeAssetPath(path);

    // imgix note
    // all the items occupy 28px of width and 28px of height

    const itemSize = 28;

    return `${this._computeAssetPath(path)}?auto=compress,format&w=${itemSize}`;
  }
}

window.customElements.define('widget-input-chips', WidgetInputChips);
