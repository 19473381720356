import { css, html, LitElement } from 'lit';

const style = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: auto;
  }
`;

class UIMain extends LitElement {
  static get styles() {
    return style;
  }

  static get properties() {
    return {
      autoMainSize: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.autoMainSize = false;
  }

  render() {
    return html` <slot></slot> `;
  }

  firstUpdated() {
    this._resizeObserver = new ResizeObserver(() => {
      // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        const event = new CustomEvent('ui-main-resize', {
          bubbles: true,
          composed: true,
        });
        this.dispatchEvent(event);
      });
    });
    this._resizeObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._resizeObserver.disconnect();
  }
}

window.customElements.define('ui-main', UIMain);
