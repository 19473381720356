import { css } from 'lit';

export const SharedStyles = css`
  :host {
    /* Material Design Layout
       https://material.google.com/layout/principles.html */
    --avatar-size: 40px;
    --icon-size: 24px;

    --card-width: 437px;
    --card-height: 533px;
    --card-border-width: 1px;

    --button-height: 64px;
    --header-toolbar-height: 64px;
    --line-height: 24px;
    --statusbar-height: 64px;

    /* Accessible Touch target
       https://www.google.com/design/spec/usability/accessibility.html#accessibility-layout */
    --touch-target-size: 48px;

    /* Material Design Grid
       https://www.google.com/design/spec/layout/responsive-ui.html#responsive-ui-grid */

    /* Base spacing unit */
    --spacing-unit: 8px;

    /* Gutter and Margin */
    --gutter: 16px;
    --gutter-width: 16px;
    --screen-edge-margin-width: 16px;
    --widget-gutter: 10px;

    /* Widget width = 478px */
    --widget-width: calc(
      var(--card-width) - 2 * var(--widget-gutter) - 2 *
        var(--card-border-width)
    );
    --widget-border-radius: 21px;

    --border-width: 1px;
    --border-radius: 20px;
    --border-radius-inner: 19px; /* 2px less than border-radius */

    --action-button-size: 48px;
    --action-button-padding: 8px;

    --buttons-aspect-ratio: 5; // 415px / 83px on screen 720p

    /* Typography */
    --button-font-size: 14px;
    --input-font-size: 15px;
    --input-error-font-size: 14px;
    --small-font-letter-spacing: 0.018em;

    --text-shadow-size: 1px;
    --small-text-shadow-size: 0.5px;

    --red-color: #fc3232;
    --green-color: #4ba72e;

    --primary-text-color: #333;
    --secondary-text-color: #737373;
    --tertiary-text-color: #000;
    --error-color: var(--red-color); /* User input errors */

    --primary-background-color: #fff; /* Content background */
    --secondary-background-color: #ebebeb; /* Main / Drawer background */
    --tertiary-background-color: #fff; /* Cards / Dialogs background */

    --default-theme-primary-color: #63beff;
    --default-theme-text-primary-color: #fff;

    --default-theme-light-primary-color: #ebf5fc;
    --default-theme-text-light-primary-color: #0095ff;

    --default-theme-dark-primary-color: #579fd4;

    --default-theme-secondary-color: #fcfcfc;
    --default-theme-text-secondary-color: #919191;

    --default-theme-accent-color: #63beff;
    --default-theme-text-accent-color: #fff;

    --default-theme-text-accent-shadow-color: rgba(0, 0, 0, 0.2);
    --default-theme-text-primary-shadow-color: rgba(0, 0, 0, 0.2);

    --default-theme-divider-color: #aaa;
    --default-theme-yes-button-color: var(--green-color);
    --default-theme-no-button-color: var(--red-color);

    --default-theme-joystick-top-gradient-color: #63beff;
    --default-theme-joystick-bottom-gradient-color: #55a4f3;
    --default-theme-joystick-text-color: #fff;
    --default-theme-joystick-shadow-color: #63beff;

    --primary-color: var(--default-theme-primary-color);
    --text-primary-color: var(--default-theme-text-primary-color);
    --text-primary-shadow-color: var(--default-theme-text-primary-shadow-color);

    --light-primary-color: var(--default-theme-light-primary-color);
    --text-light-primary-color: var(--default-theme-text-light-primary-color);

    --dark-primary-color: var(--default-theme-dark-primary-color);

    --secondary-color: var(--default-theme-secondary-color);
    --text-secondary-color: var(--default-theme-text-secondary-color);

    --accent-color: var(--default-theme-accent-color);
    --text-accent-color: var(--default-theme-text-accent-color);
    --text-accent-shadow-color: var(--default-theme-text-accent-shadow-color);

    --divider-color: var(--default-theme-divider-color);
    --yes-button-color: var(--default-theme-yes-button-color);
    --no-button-color: var(--default-theme-no-button-color);

    --action-button-top-gradient-color: var(
      --default-theme-joystick-top-gradient-color
    );
    --action-button-bottom-gradient-color: var(
      --default-theme-joystick-bottom-gradient-color
    );
    --action-button-text-color: var(--default-theme-joystick-text-color);
    --action-button-shadow-color: var(--default-theme-joystick-shadow-color);

    --md-sys-color-primary: var(--accent-color);

    --fill-color: #f5f5f5;

    font-family: 'Roboto', 'Noto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  * {
    outline-width: 0;
  }

  [hidden] {
    display: none !important;
  }

  a {
    color: var(--accent-color);
    cursor: pointer;
    text-decoration: none;
    text-shadow: 0 0.5px 0 rgba(0, 0, 0, 0);
    font-size: var(--button-font-size);
    outline-width: 0;
  }

  b,
  strong {
    font-weight: 500;
  }

  /* width */
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  .scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ddd;
    border-radius: 4px;
  }

  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) and (min-height: 480px) {
    :host {
      --button-font-size: 15px;
    }
  }

  /* Desktop 720p */
  @media screen and (min-width: 600px) and (max-height: 700px) {
    :host {
      --action-button-size: 40px;
    }
  }

  /* Desktop 1080p */
  @media screen and (min-width: 600px) and (min-height: 700px) {
    :host {
      --card-width: 512px;
      --card-height: 625px;
      --widget-gutter: 16px;
    }
  }
`;
