import { css, html } from 'lit';

import { WidgetBaseElement } from './widget-base-element.js';
import { customEvent } from './utils/utils.js';
import './ui-parts/ui-container.js';
import './ui-parts/ui-main-image.js';
import './ui-parts/ui-title.js';

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
    --ui-main-image-padding-bottom: 0;
  }

  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ui-title {
    max-height: 250px;
    border-radius: 64px;
    box-shadow: rgb(0 0 0 / 15%) 8px 8px 24px 0px;
    background-color: #fff;
  }
`;

export class WidgetOutro extends WidgetBaseElement {
  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * URL of the image to display.
       */
      image: { type: String },

      /**
       * Text for the bubble.
       */
      message: {
        type: String,
      },

      /**
       * Exit URL for redirect to new site.
       */
      exitUrl: {
        type: String,
      },

      /**
       * Text of the action button.
       */
      buttonText: {
        type: String,
      },

      /**
       * Does this widget mean it's the end of qset? (Can be used to create inline outros.)
       */
      screenOut: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.message = '';
    this.exitUrl = '';
    this.buttonText = 'Další minuta';
    this.screenOut = true;

    this.addEventListener('enter-stage', this._onEnterStage);
  }

  render() {
    return html`
      <ui-container isFlex>
        ${this.message
          ? html`<ui-title .question="${this.message}"></ui-title>`
          : ''}

        <ui-main-image
          src="${this._computeAssetPath(this.image)}"
          question="${this.question}"
        ></ui-main-image>
      </ui-container>
    `;
  }

  _onEnterStage() {
    this.dispatchEvent(
      customEvent('set-next-action-button-text', this.buttonText, true),
    );

    if (this.exitUrl) {
      this.dispatchEvent(customEvent('set-exit-url', this.exitUrl, true));
    }

    if (this.screenOut) {
      // hide the next action button if we're not exiting and we're not in Trendaro app
      if (this._hostApp !== 'trendaro')
        this._hideNextActionButton = !this.exitUrl;

      this.answer = ['seen'];

      this.dispatchEvent(customEvent('finish-qset', null, true));
    } else {
      this.answer = ['seen'];
    }
  }
}

window.customElements.define('widget-outro', WidgetOutro);
