import { css, html } from 'lit';

import { WidgetBaseElement } from './widget-base-element.js';
import { WidgetMultiChoiceMixin } from './mixins/widget-multi-choice-mixin.js';
import { RandomizeMixin } from './mixins/randomize-mixin.js';

import './ui-parts/ui-buttons.js';
import './ui-parts/ui-container.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-main-image.js';
import './ui-parts/ui-title.js';

const styles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  ui-main {
    position: relative;
  }
`;

export class WidgetButtons extends WidgetMultiChoiceMixin(
  RandomizeMixin(WidgetBaseElement),
) {
  static get styles() {
    return [super.styles, styles];
  }

  static get properties() {
    return {
      /**
       * URL path to image.
       */
      image: { type: String },

      /**
       * The options to show. Array of `Object`s:
       * - `id` is used as slug to save the answer, if `true` or `false`,
       *   special background colour is used when selected.
       * - `image` optional url for image
       *   2 buttons have image size 197x78px, for image quality 2x 394x156px, aspect ratio 2.53:1
       *   3 buttons have image size 131x78px, 2x 262x156px, aspect ratio 1.68:1
       * - `icon` optional url for icon, always SVG
       * - `label` optional text to display
       *
       * In Minute there should be max 3 buttons.
       * @type {Array<object>}
       */
      values: { type: Array },

      imageWithText: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.image = '';
    this.values = [];

    /**
     *  If true, show the image with text in double size 2x.
     */
    this.imageWithText = false;
  }

  render() {
    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
          ?imageWithText="${this.imageWithText}"
        ></ui-main-image>

        <ui-main>
          <ui-buttons
            .buttons="${this._encodeValues(this.values)}"
            .mediaBaseUrl="${this._mediaBaseUrl}"
            ?multi="${this.multi}"
            ?rtl="${this._rtl}"
            @selection-changed="${e => {
              this._selection = e.detail.selection;
            }}"
          ></ui-buttons>
        </ui-main>
      </ui-container>
    `;
  }
}

window.customElements.define('widget-buttons', WidgetButtons);
