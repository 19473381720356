import { css, html, LitElement } from 'lit';

import { StageSimpleCardStyles } from './stage-simple-card.js';
import { customEvent } from '../utils/utils.js';

import '../ui-parts/ui-progress-border.js';
import '../ui-parts/ui-spinner.js';

const styles = css`
  :host([disabled]) {
    pointer-events: none;
  }

  :host([animationdisabled]) {
    transform: translateX(0) translateY(0) translateZ(0) rotate(0);
  }

  ui-spinner {
    --ui-spinner-bg-color: #ddd;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 5;
    border-radius: 19px;
  }
`;

class StageCard extends LitElement {
  constructor() {
    super();

    /**
     * If true, disable the card.
     */
    this.disabled = false;

    this._progressBorderValue = 0;
  }

  static get styles() {
    return [StageSimpleCardStyles, styles];
  }

  static get properties() {
    return {
      /**
       * Show the spinner inside the card.
       */
      showSpinner: {
        type: Boolean,
      },

      disabled: {
        type: Boolean,
        reflect: true,
      },

      animationDisabled: {
        type: Boolean,
        reflect: true,
      },

      _progressBorderValue: { attribute: false },
    };
  }

  render() {
    return html`
      <ui-progress-border
        .value="${this._progressBorderValue}"
        @progress-finished="${this._onProgressBorderFinished}"
      >
        <slot></slot>
      </ui-progress-border>

      <ui-spinner ?visible=${this.showSpinner}></ui-spinner>
    `;
  }

  /**
   * Start the countdown with progress border animation.
   * @param {number} time - The time in seconds.
   */
  startCountdown(time) {
    // stop previous countdown timer
    this.stopCountdown();

    // start the countdown timer
    // the time is multiply by 2 to get the correct time in seconds (1 second = 2 ticks)
    // wen don't wait 1 second to start ui-progress-border animation (see _startCountdownTimer)
    const countdownTicksPerSecond = 2;
    this._countdownTime = time * countdownTicksPerSecond;
    this._startCountdownTimer(this._countdownTime, countdownTicksPerSecond);
  }

  /**
   * Stop the countdown timer and reset the countdown progress bar.
   */
  stopCountdown() {
    if (this._countdownTimeoutId) {
      clearTimeout(this._countdownTimeoutId);

      // reset the countdown progress bar
      this.shadowRoot.querySelector('ui-progress-border').reset();
    }
  }

  /**
   * Start the countdown timer.
   */
  _startCountdownTimer(time, countdownTicksPerSecond) {
    let currentTime = time || 0;

    if (currentTime === 0) {
      // reset the countdown progress bar if the time is up
      this._progressBorderValue = 0;
    } else {
      // update the countdown progress bar
      this._progressBorderValue = Math.round(
        (currentTime / this._countdownTime) * 100,
      );
    }

    if (currentTime === 1) {
      this.dispatchEvent(customEvent('countdown-last-tick'));
    }

    // update the countdown time
    currentTime -= 1;

    // start the countdown timer again if the time is not up
    if (currentTime >= 0) {
      this._countdownTimeoutId = setTimeout(() => {
        this._startCountdownTimer(currentTime, countdownTicksPerSecond);
      }, 1000 / countdownTicksPerSecond);
    }
  }

  _onProgressBorderFinished() {
    this.dispatchEvent(customEvent('countdown-ended'));
  }
}

window.customElements.define('stage-card', StageCard);
