import { css } from 'lit';
import { MdFilledTextField } from '@material/web/textfield/filled-text-field';
import { onIOS } from '../utils/browser.js';

class UITextField extends MdFilledTextField {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          resize: vertical;

          --md-sys-color-primary: var(--tertiary-text-color);
          --md-filled-field-container-color: var(--fill-color);
        }
      `,
    ];
  }

  async firstUpdated() {
    super.firstUpdated();

    // md-filled-field element
    const field = this.shadowRoot.querySelector('.field');

    await field.updateComplete;

    // fix for iOS, prevent mobile keyboard from covering html input
    // the input with opacity 0 causes the keyboard to cover the input
    if (onIOS) {
      // input wrapper
      field.shadowRoot.querySelector(
        '.container .middle .content',
      ).style.opacity = 1;
    }
  }
}

window.customElements.define('ui-textfield', UITextField);
