import { css, html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { WidgetBaseElement } from './widget-base-element.js';

import './ui-parts/ui-container.js';
import './ui-parts/ui-title.js';
import './ui-parts/ui-main.js';
import './ui-parts/ui-buttons.js';
import './ui-parts/ui-image.js';
import { now } from './utils/utils.js';

const styles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
    overflow: auto;
  }

  .pair {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
  }

  .pair.animate-hide .pair-item {
    animation: hide 0.4s linear;
  }

  .pair.animate-show .pair-item {
    animation: show 0.1s ease-out;
  }

  .pair-item-container {
    border: 1px solid var(--divider-color);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--fill-color);
    aspect-ratio: 1 / 1;
    width: calc(50% - 4px);
    height: auto;
  }

  .pair-item {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    background-color: white;
  }

  .pair-item .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: -0.36px;
    color: var(--tertiary-text-color);
    user-select: none;
    text-align: center;
    width: calc(100% - 8px);
    hyphens: auto; /* get language from html lang attribute */
    word-break: normal;
    overflow-wrap: normal;
  }

  .pair-item .image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .pair-item .image ui-image {
    display: flex;
    align-items: center;
    border-radius: 8px;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    --ui-image-width: 100%;
  }

  .pair .line {
    width: 8px;
    min-width: 8px;
    height: 1px;
    border-top: 1px solid var(--divider-color);
    margin: auto 0;
  }

  ui-main {
    overflow: hidden;
    position: relative;
  }

  ui-buttons.disabled {
    pointer-events: none;
  }

  @keyframes hide {
    0% {
      opacity: 1;
    }

    30% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /* large mobile wide */
  @media screen and (min-width: 370px) {
    .pair-item-container {
      width: 50%;
      max-width: 172px;
    }

    .pair .line {
      width: 50%;
      max-width: calc(100% - 344px);
    }

    .pair-item .text {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;

/**
  ## Random Pairs

  Show random pairs of attributes (brand logos) and ask user if they are related.

  Answer is array of objects: {optionFirstId, optionSecondId, buttonId, responseTime}
  If user missed answer in timeout, buttonId = "none".

  Words in items are auto hyphenated when text wraps across multiple lines. Or you can use `&shy;` HTML entity.
  Auto hyphenation is based on the language set in the HTML lang attribute.
*/
export class WidgetRandomPairs extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * Array of texts (attributes) and images (brand logos).
     * Item is object: {id, text | image, condition} - If text and image are filled, we give preference to the image.
     * @type {Array<object>}
     */
    this.optionsFirst = [];

    /**
     * Array of texts (attributes) and images (brand logos).
     * Item is object: {id, text | image, condition} - If text and image are filled, we give preference to the image.
     * @type {Array<object>}
     */
    this.optionsSecond = [];

    /**
     * Array of buttons.
     * Item is object: {id, label}.
     * @type {Array<object>}
     */
    this.buttons = [
      { id: 'ano', label: 'ano' },
      { id: 'ne', label: 'ne' },
    ];

    /**
     * Number of pairs.
     * @type {number}
     */
    this.randomPairCount = 4;

    /**
     * Timeout for a pair in seconds.
     * @type {number}
     */
    this.itemTimeout = 4;

    /**
     * Direction of a pair in UI. Values are `horizontal` or `vertical`.
     * Direction whether a pair is above each other or side by side
     * (we want to have it on all devices the same because of the quality of data).
     * @type {string}
     */
    this.direction = 'horizontal';

    this._answer = [];
    this._pairIndex = 0;
    this._firstItem = {};
    this._secondItem = {};
    this._images = [];
    this._pairAnimationCounter = 0;
    this._displayedOptions = [];

    // Bind event handlers to `this` (since event handlers are invoked on the element).
    // Function removeEventListener needs to be bound to the same function reference.
    this._onPairAnimationEndBind = this._onPairAnimationEnd.bind(this);

    this.addEventListener('enter-stage', this._onEnterStage);
    this.addEventListener('exit-stage', this._onExitStage);
    this.addEventListener('countdown-ended', this._onCountdownEnded);
  }

  static get styles() {
    return [super.styles, styles];
  }

  /**
   * Reactive properties of the element.
   * @see https://lit.dev/docs/components/properties/
   */
  static get properties() {
    return {
      _firstItem: { attribute: false },

      _secondItem: { attribute: false },

      _displayedOptions: { attribute: false },
    };
  }

  /**
   * Render method. Called whenever reactive properties change.
   * @see https://lit.dev/docs/components/rendering/
   */
  render() {
    // if no pairs, return empty template
    if (this._displayedOptions?.length === 0) return html``;

    const renderItem = item => html`
      ${item.image
        ? html`
            <div class="image">
              <ui-image
                .src="${this._getImagePath(item.image)}"
                srcset="${ifDefined(
                  this._computeImageSrcSet(
                    this._getImagePath.bind(this),
                    item.image,
                  ),
                )}"
                @ui-image-waiting-for-media="${e => e.stopPropagation()}"
                @ui-image-media-loaded="${e => e.stopPropagation()}"
              ></ui-image>
            </div>
          `
        : null}
      ${item.text && !item.image
        ? html`<div class="text">${unsafeHTML(item.text)}</div>`
        : null}
    `;

    return html`
      <ui-container isFlex has-scroll>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
          ?hidden="${!this.question}"
        ></ui-title>

        <ui-main>
          <div class="pair">
            <div class="pair-item-container">
              <div class="pair-item first">${renderItem(this._firstItem)}</div>
            </div>
            <div class="line"></div>
            <div class="pair-item-container">
              <div class="pair-item second">
                ${renderItem(this._secondItem)}
              </div>
            </div>
          </div>

          <ui-buttons
            .buttons="${this._encodeValues(this.buttons)}"
            .mediaBaseUrl="${this._mediaBaseUrl}"
            ?rtl="${this._rtl}"
            @selection-changed="${this._onSelectionChanged}"
          ></ui-buttons>
        </ui-main>
      </ui-container>
    `;
  }

  /**
   * Lifecycle callback.
   * @override WidgetBaseElement `firstUpdated` method.
   */
  firstUpdated() {
    super.firstUpdated();

    // get all pairs for preload images
    // we preload all images because we don't know which pairs will be shown,
    // option condition must be evaluated in enter stage event handler
    this._pairs = this._createRandomPairs(
      this.optionsFirst,
      this.optionsSecond,
    ).slice(0, this.randomPairCount);

    // preload images of all pairs (if any)
    if (this._pairs.length) this._preloadImages();
    // send ready event if no pairs
    else this._fireReadyEvent();
  }

  /**
   * Enter stage event handler.
   */
  async _onEnterStage() {
    // getting options with condition in this place is important
    // for correct evaluation of condition contains answer from previous stage
    const optionsFirst = this._getOptions(this.optionsFirst);
    const optionsSecond = this._getOptions(this.optionsSecond);

    // if no options, set empty array to pairs
    if (!optionsFirst.length || !optionsSecond.length) {
      this._pairs = [];
    } else {
      // save displayed options for additional data on finish
      this._saveOptions(optionsFirst, optionsSecond);

      // wait for `_displayedOptions` to be rendered
      await this.updateComplete; // wait for render

      // get elements
      this._pair = this.shadowRoot.querySelector('.pair');
      this._buttons = this.shadowRoot.querySelector('ui-buttons');

      // get new pairs from evaluated options
      this._pairs = this._createRandomPairs(optionsFirst, optionsSecond).slice(
        0,
        this.randomPairCount,
      );
    }

    // if no pairs, finish
    if (!this._pairs.length) {
      // no waiting for click on next button
      this._skipNextActionButton = true;

      this.answer = [];
      this._advanceQuestion();

      return;
    }

    // reset pair index
    this._pairIndex = 0;

    // add animation end event listener to pair element
    this._pair.addEventListener('animationend', this._onPairAnimationEndBind);

    // show first pair
    await this._showRandomPair(this._pairIndex);

    this._startCountdown(this.itemTimeout);
    this._startTime = now();
  }

  /**
   * Exit stage event handler.
   */
  _onExitStage() {
    // remove animation end event listener from pair element
    this._pair?.removeEventListener(
      'animationend',
      this._onPairAnimationEndBind,
    );
  }

  /**
   * Get options with condition evaluated to true or all options if no condition is set.
   * @param {array} options - Array of options. Item is object: {id, text | image, condition}
   * @returns {array}
   */
  _getOptions(options) {
    return options.filter(option => {
      if (option.condition) return this._evalCondition(option.condition);

      // save option without condition
      return true;
    });
  }

  /**
   * Save displayed options.
   * @param {array} optionsFirst
   * @param {array} optionsSecond
   */
  _saveOptions(optionsFirst, optionsSecond) {
    optionsFirst.forEach(option =>
      this._displayedOptions.push({ type: 'first', id: option.id }),
    );

    optionsSecond.forEach(option =>
      this._displayedOptions.push({ type: 'second', id: option.id }),
    );
  }

  /**
   * Handle show and hide animation end events for pair.
   * The event is fired for each animation of the pair. We need to distinguish between them.
   */
  async _onPairAnimationEnd(event) {
    // count animations, because we have two events for each animation
    // the pair has two animated items
    this._pairAnimationCounter += 1;

    // end of hide animation
    if (event.animationName === 'hide' && this._pairAnimationCounter === 2) {
      // reset counter for next animation
      this._pairAnimationCounter = 0;

      // remove animation class
      this._pair.classList.remove('animate-hide');

      // show next pair
      await this._showRandomPair(this._pairIndex);
      this._startTime = now();

      // start show animation of the pair
      this._pair.classList.add('animate-show');
    }

    // end of show animation
    if (event.animationName === 'show' && this._pairAnimationCounter === 2) {
      // reset counter for next animation
      this._pairAnimationCounter = 0;

      // remove animation class
      this._pair.classList.remove('animate-show');

      // enable buttons
      this._buttons.classList.remove('disabled');

      // for testing
      this.dispatchEvent(
        new CustomEvent('testing-animation-ended', {
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  /**
   * Process next pair.
   */
  _nextPair() {
    this._pairIndex += 1;
    this._selectedButton = undefined;

    // disable buttons until next pair is shown
    this._buttons.classList.add('disabled');

    // start hide animation of the pair
    this._pair.classList.add('animate-hide');
  }

  /**
   * Save answer and process next pair.
   */
  _saveAnswer() {
    const responseTime = Math.round(now() - this._startTime);

    this._answer.push({
      optionFirstId: this._pairs[this._pairIndex][0].id,
      optionSecondId: this._pairs[this._pairIndex][1].id,
      buttonId: this._selectedButton
        ? this._decodeSlug(this._selectedButton)
        : 'none',
      responseTime,
    });

    // if last pair, save answer and finish
    if (this._pairIndex + 1 === this._pairs.length) {
      // no waiting for click on next button
      this._skipNextActionButton = true;

      // save showed options to additional data
      if (this._displayedOptions.length) {
        this.additionalData = { displayedOptions: this._displayedOptions };
      }

      this.answer = this._answer;
    } else {
      this._startCountdown(this.itemTimeout);
      this._nextPair();
    }
  }

  /**
   * Countdown ended event handler.
   * @param {object} event
   */
  _onCountdownEnded() {
    this._saveAnswer();
  }

  /**
   * Randomize array. Return a copy of the `array`, randomly shuffled.
   * "Durstenfeld shuffle"
   * http://stackoverflow.com/a/12646864/1496234
   * @param {array} array
   * @returns array
   */
  _randomizeArray(array) {
    const _array = array.slice();
    for (let i = _array.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = _array[i];
      _array[i] = _array[j];
      _array[j] = temp;
    }
    return _array;
  }

  /**
   * Combine two arrays using cartesian product (all combinations).
   * @param {array} array1
   * @param {array} array2
   * @returns array
   */
  _combineArrays(array1, array2) {
    const combinedArray = [];
    array1.forEach(item => {
      array2.forEach(item2 => {
        combinedArray.push([item, item2]);
      });
    });
    return combinedArray;
  }

  /**
   * Create random pairs.
   * @param {array} array1
   * @param {array} array2
   * @returns array
   */
  _createRandomPairs(array1, array2) {
    const combinedArray = this._combineArrays(array1, array2);
    const randomArray = this._randomizeArray(combinedArray);
    return randomArray;
  }

  /**
   * Show random pair.
   * @param {number} index - Index of pair.
   * @returns {Promise} - Resolves when pair is shown.
   */
  async _showRandomPair(index) {
    [this._firstItem, this._secondItem] = this._pairs[index];
    return this.updateComplete; // wait for render
  }

  /**
   * Selection changed event handler.
   * @param {object} event - Selection changed event. Contains `detail.selection` property.
   */
  _onSelectionChanged(event) {
    [this._selectedButton] = event.detail.selection;

    // Deselect a button
    this._buttons.reset();

    this._saveAnswer();
  }

  /**
   * Get image path.
   * @param {string} path - Image path.
   * @returns {string}
   */
  _getImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf('/') !== -1) return this._computeAssetPath(path);

    return `${this._computeAssetPath(
      path,
    )}?auto=compress,format&h=170&w=170&fit=clamp`;
  }

  /**
   * Get unique images from pairs array. Used for preloading.
   * @param {array} pairs - Array of pairs. Item is array of two objects: {id, text | image}
   * @returns {array}
   */
  _getUniqueImages(pairs) {
    const images = new Set();

    pairs.forEach(pair => {
      const [first, second] = pair;

      if (first.image) images.add(first.image);
      if (second.image) images.add(second.image);
    });

    return images;
  }

  /**
   * Preload images.
   */
  _preloadImages() {
    this._loadStartTime = +new Date();

    this._getUniqueImages(this._pairs).forEach(image => {
      const img = new Image();
      img.src = this._getImagePath(image);

      const srcset = this._computeImageSrcSet(
        this._getImagePath.bind(this),
        image,
      );
      if (srcset) img.srcset = srcset;

      img.addEventListener(
        'load',
        () => {
          this.dispatchEvent(
            new CustomEvent('media-loaded', {
              detail: {
                src: img.src,
                loadTime: +new Date() - this._loadStartTime,
              },
            }),
          );
        },
        { once: true },
      ); // once: true - remove event listener after load

      this.dispatchEvent(
        new CustomEvent('waiting-for-media', {
          detail: { src: img.src },
        }),
      );

      // save image to array to prevent garbage collection
      this._images.push(img);
    });
  }
}

window.customElements.define('widget-random-pairs', WidgetRandomPairs);
