// Inspired by https://codepen.io/AshBardhan/pen/dNKwXz

import { css, html, LitElement } from 'lit';

const style = css`
  :host {
    --size: var(--ui-emoji-size, 80px);
    --line-width: var(--ui-emoji-line-width, calc(var(--size) / 16));

    display: inline-block;
    width: var(--size);
    height: var(--size);
    background: var(--ui-emoji-color, #ffda6a);
    border-radius: 50%;
    position: relative;
    /* transform: scale(0.6); */
  }

  .face,
  .eyebrows,
  .eyes,
  .mouth {
    position: absolute;
  }

  .face:before,
  .face:after,
  .eyebrows:before,
  .eyebrows:after,
  .eyes:before,
  .eyes:after,
  .mouth:before,
  .mouth:after {
    position: absolute;
    content: '';
  }

  .face {
    width: inherit;
    height: inherit;
  }

  :host([type='yay'][animated]) .face {
    animation: yay 2s linear infinite alternate;
  }

  :host([type='yay']) .eyebrows {
    left: calc(50% - var(--line-width) / 2);
    top: calc(var(--size) / 3);
    height: var(--line-width);
    width: var(--line-width);
    border-radius: 50%;
    background: transparent;
    box-shadow:
      calc(var(--line-width) * -1) 0 0 0 var(--ui-emoji-line-color, #000),
      calc(var(--line-width) * -4) 0 0 0px var(--ui-emoji-line-color, #000),
      var(--line-width) 0 0 0 var(--ui-emoji-line-color, #000),
      calc(var(--line-width) * 4) 0 0 0px var(--ui-emoji-line-color, #000);
  }

  :host([type='yay']) .eyebrows:before,
  :host([type='yay']) .eyebrows:after {
    width: calc(var(--line-width) * 4);
    height: calc(var(--line-width) * 2);
    border-radius: calc(var(--size) / 2) calc(var(--size) / 2) 0 0;
    background: transparent;
    border: var(--line-width) solid var(--ui-emoji-line-color, #000);
    box-sizing: border-box;
    border-bottom: 0;
    bottom: calc(var(--line-width) / 2);
    left: calc(50% - var(--line-width) * 2);
  }

  :host([type='yay']) .eyebrows:before {
    margin-left: calc(var(--line-width) * -2 - var(--line-width) / 2);
  }

  :host([type='yay']) .eyebrows:after {
    margin-left: calc(var(--line-width) * 2 + var(--line-width) / 2);
  }

  :host([type='yay']) .mouth {
    top: calc(var(--size) * 2 / 3);
    background: transparent;
    left: 50%;
  }

  :host([type='yay']) .mouth:after {
    width: calc(var(--size) * 5 / 8);
    height: calc(var(--size) * 5 / 8);
    left: calc(50% - var(--size) * 5 / 16);
    top: calc(var(--size) * -5 / 8);
    border-radius: 50%;
    background: transparent;
    border: var(--line-width) solid var(--ui-emoji-line-color, #000);
    box-sizing: border-box;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 1;
  }

  :host([type='yay']) .mouth:before {
    width: var(--line-width);
    height: var(--line-width);
    background: transparent;
    border-radius: 50%;
    bottom: calc(var(--size) / 12);
    left: calc(50% - var(--line-width) / 2);
    box-shadow:
      calc((var(--line-width) * -3) - 1px) 0 0 0
        var(--ui-emoji-line-color, #000),
      calc((var(--line-width) * 3) + 1px) 0 0 0 var(--ui-emoji-line-color, #000),
      calc(var(--line-width) * -5) -2px calc(var(--line-width) * 4) calc(
          var(--line-width) * 3 / 2
        ) var(--ui-emoji-mouth-color, #d5234c),
      calc(var(--line-width) * 5) -2px calc(var(--line-width) * 4) calc(
          var(--line-width) * 3 / 2
        ) var(--ui-emoji-mouth-color, #d5234c);
  }

  :host([type='sad'][animated]) .face {
    animation: sad-face 2s ease-in infinite;
  }
  :host([type='sad']) .eyebrows {
    left: calc(50% - var(--line-width) / 2);
    top: calc(var(--size) / 3 - 2px);
    height: var(--line-width);
    width: var(--line-width);
    border-radius: 50%;
    background: transparent;
    /* box-shadow: calc(var(--line-width) * -6 + 2px) calc(var(--line-width) + 2px) 0 0 var(--ui-emoji-line-color, #000), calc(var(--line-width) * -3) 0 0 0 var(--ui-emoji-line-color, #000), calc(var(--line-width) * 3) 0 0 0 var(--ui-emoji-line-color, #000),
    calc(var(--line-width) * 6 - 1px) calc(var(--line-width) + 2px) 0 0 var(--ui-emoji-line-color, #000); */
  }
  :host([type='sad']) .eyebrows:before,
  :host([type='sad']) .eyebrows:after {
    width: calc(var(--line-width) * 5);
    height: calc(var(--line-width) * 4);
    border: var(--line-width) solid var(--ui-emoji-line-color, #000);
    box-sizing: border-box;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    top: 0;
    left: calc(50% - var(--line-width) * 5 / 2);
  }
  :host([type='sad']) .eyebrows:before {
    margin-left: calc(var(--line-width) * -4);
    transform: rotate(-30deg);
  }
  :host([type='sad']) .eyebrows:after {
    margin-left: calc(var(--line-width) * 4);
    transform: rotate(30deg);
  }
  :host([type='sad']) .eyes {
    width: calc(var(--line-width) * 2);
    height: calc(var(--line-width) * 2 + 4px);
    left: calc(50% - var(--line-width));
    top: calc(var(--size) / 2 - var(--line-width));
    border-radius: 50%;
    background: transparent;
    box-shadow:
      calc(var(--line-width) * 4) 0 0 0 var(--ui-emoji-line-color, #000),
      calc(var(--line-width) * -4) 0 0 0 var(--ui-emoji-line-color, #000);
  }

  :host([type='sad'][tear-drop][animated]) .eyes:after {
    background: #548dff;
    width: 12px;
    height: 12px;
    margin-left: var(--line-width);
    border-radius: 0 100% 40% 50%/0 50% 40% 100%;
    transform-origin: 0% 0%;
    animation: tear-drop 2s ease-in infinite;
  }

  :host([type='sad']) .mouth {
    width: calc(var(--size) / 2);
    height: calc(var(--size) / 2);
    left: calc(50% - var(--size) / 4);
    top: calc(var(--size) * 2 / 3);
    box-sizing: border-box;
    border: var(--line-width) solid var(--ui-emoji-line-color, #000);
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    background: transparent;
  }
  :host([type='sad'][animated]) .mouth {
    animation: sad-mouth 2s ease-in infinite;
  }
  :host([type='sad']) .mouth:after {
    width: var(--line-width);
    height: var(--line-width);
    background: transparent;
    border-radius: 50%;
    top: 0;
    left: calc(50% - var(--line-width) / 2);
    box-shadow:
      calc(var(--line-width) * -2 - var(--line-width) / 2) 0 0 0
        var(--ui-emoji-line-color, #000),
      calc(var(--line-width) * 2 + var(--line-width) / 2) 0 0 0
        var(--ui-emoji-line-color, #000);
  }

  @keyframes yay {
    25% {
      transform: rotate(-15deg);
    }
    75% {
      transform: rotate(15deg);
    }
  }

  @keyframes sad-face {
    25%,
    35% {
      top: -10px;
    }
    55%,
    95% {
      top: 5px;
    }
    100%,
    0% {
      top: 0;
    }
  }

  @keyframes sad-mouth {
    25%,
    35% {
      transform: scale(0.85);
      top: calc((var(--size) * 2 / 3) - 10px);
    }
    55%,
    100%,
    0% {
      transform: scale(1);
      top: calc(var(--size) * 2 / 3);
    }
  }

  @keyframes tear-drop {
    0%,
    100% {
      display: block;
      left: 35px;
      top: 15px;
      transform: rotate(45deg) scale(0);
    }
    25% {
      display: block;
      left: 35px;
      transform: rotate(45deg) scale(2);
    }
    49.9% {
      display: block;
      left: 35px;
      top: 65px;
      transform: rotate(45deg) scale(0);
    }
    50% {
      display: block;
      left: -35px;
      top: 15px;
      transform: rotate(45deg) scale(0);
    }
    75% {
      display: block;
      left: -35px;
      transform: rotate(45deg) scale(2);
    }
    99.9% {
      display: block;
      left: -35px;
      top: 65px;
      transform: rotate(45deg) scale(0);
    }
  }
`;

class UIEmoji extends LitElement {
  static get styles() {
    return style;
  }

  render() {
    return html`
      <div class="face">
        <div class="eyebrows"></div>
        <div class="eyes"></div>
        <div class="mouth"></div>
      </div>
    `;
  }
}

window.customElements.define('ui-emoji', UIEmoji);
