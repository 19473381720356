import dslRandomize from '@behavio/bh-dsl/randsl';
import * as dsl from '@behavio/bh-dsl';

/**
 * ## Hierarchical randomizer
 *
 * Randomizes objects according to a tree-like structure. This
 * enables things like random ordering for pairs of objects.
 * It is supposed to work for questions in qset, and for answers
 * inside each question.
 *
 * ## Randomization DSL (ranDSL)
 * To describe what can be randomized and what has to be kept fixed,
 * create a recipe consisting of ranDSL functions and object ids.
 * Not all the questions have to be mentioned in the recipe.
 *
 * ### Functions
 *
 * * `(random-shuffle q1 q2 q3)` - completely random order for `q1, q2, q3`
 * * `(random-flip q1 q2 q3)` - keep ordering, `q1, q2, q3` or `q3, q2, q1`
 * * `(random-pick 2 q1 q2 q3)` - pick two from `q1, q2, q3`, shuffle
 * * `(bind q3 q2 q1)` - fix the order `q3, q2, q1`
 * * `(as-is q3 q2 q1)` - keep the previously assigned slots - `q1, q2, q3`
 *
 * ### Example 1
 * Randomize ordering of 3 consecutive questions:
 *
 *     (random-shuffle q2 q3 q4)
 *
 * ### Example 2
 * Randomize order of 2 coupled question pairs, keeping `q4` and `q5` in place.
 *
 *     (random-shuffle
 *       (bind q2 q3)
 *       (bind q6 q7))
 */
export const RandomizeMixin = superclass =>
  class extends superclass {
    constructor() {
      super();
      this.randomizeRecipe = null;
      this._randomizedValues = [];
    }

    static get properties() {
      return {
        /**
         * A [ranDSL](#TrendaroBehaviors.RandomizeBehavior) recipe, which will be applied
         * to the contents of `values` array.
         */
        randomizeRecipe: {
          type: String,
        },

        _randomizedValues: {
          type: Array,
        },
      };
    }

    // Called before update() to compute values needed during the update.
    willUpdate(changedProperties) {
      if (super.willUpdate) super.willUpdate(changedProperties);

      if (
        changedProperties.has('values') ||
        changedProperties.has('randomizeRecipe')
      ) {
        this._randomizeOnce(this.values, this.randomizeRecipe);
      }
    }

    // widget can be attached multiple times, so take care to avoid re-randomization
    _randomizeOnce(values, recipe) {
      // bail out if
      // - no randomization is necessary
      // - this instance is already randomized
      // TODO: alternatively we could set the recipe to empty string
      // after randomization to avoid re-randomization..
      if (
        !recipe ||
        (this._randomizedValues && this._randomizedValues.length)
      ) {
        return;
      }

      if (recipe) {
        // FIXME: this is some kind of special hack for widget-buttons, where
        // icons were shown incorrectly with randomization
        // as we're randomizing now before attachment, this is probably not needed any more
        this._randomizedValues = this._randomize(values, recipe);

        this.values = this._randomizedValues;
      }
    }

    // randomize `objects` Array according to `recipe`
    _randomize(objects, recipe) {
      // no recipe means no randomization
      if (!recipe) return objects;

      return dslRandomize(objects, dsl.loads(recipe)[0]);
    }
  };
