import { css, html, LitElement } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import './ui-textfield.js';

const style = css`
  :host {
    display: block;
  }

  ui-textfield {
    --md-sys-color-primary: var(--tertiary-text-color);
    --md-filled-field-container-color: var(--fill-color);

    width: 100%;
  }

  #dropdown {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
    overflow: hidden;
    font-size: 15px;
    visibility: hidden;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
    opacity: 0;
  }

  :host([active]) #dropdown {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .delimiter {
    padding-bottom: 4px;
    margin: 0 16px;
  }

  .suggestions {
    padding: 0; /* Edge browser */
    margin: 0;
    list-style-type: none;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    max-height: 100px;
    overflow: auto;
  }

  .suggestion {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 16px;
    color: #000;
  }

  .suggestion[selected] {
    background-color: #eee;
  }

  .suggestion-icon {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    font-size: 20px;
    color: #333;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
  }

  .suggestion-label {
    padding: 6px 0;
    font-size: inherit;
    line-height: 18px;
    text-align: left;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
  }
`;

class UiComboBox extends LitElement {
  static get styles() {
    return [style];
  }

  render() {
    const renderSuggestion = (suggestion, index) => html`
      <li
        class="suggestion index${index}"
        role="presentation"
        ?selected="${this._selectedItemIndex === index}"
        @click="${() => this._handleSelectedItem(index)}"
        @mouseover="${() => {
          this._selectedItemIndex = index;
        }}"
      >
        <div
          class="suggestion-label"
          role="option"
          aria-selected="${this._selectedItemIndex === index}"
        >
          ${unsafeHTML(suggestion)}
        </div>
      </li>
    `;

    return html`
      <ui-textfield
        id="input"
        .value="${this.value}"
        label="${this.label}"
        @click="${() => this.dispatchEvent(new Event('input-click'))}"
        @input="${this._onInputInput}"
        @keydown="${this._onInputKeyDown}"
        @keyup="${this._onInputKeyUp}"
      ></ui-textfield>

      <div id="dropdown">
        <div class="delimiter"></div>
        <ul class="suggestions">
          ${this._suggestions.map(renderSuggestion)}
        </ul>
        <slot name="dropdown-bottom"></slot>
      </div>
    `;
  }

  static get properties() {
    return {
      // Active state of the combo box element.
      active: { type: Boolean, reflect: true },
      // Switch for hide the input field.
      inputHidden: { type: Boolean },
      // Current suggestions of the combo box.
      suggestions: { type: Array },
      // Maximally shown suggestions in the dropdown.
      maxSuggestions: { type: Number },
      // Minimum input size for generating suggestions.
      minInput: { type: Number },
      // Placeholder for the input field.
      label: { type: String },
      // Input value.
      value: { type: String },
      // Allow entering custom values that are not included in the list of suggestions.
      allowCustomValue: { type: Boolean },

      _selectedItemIndex: { type: Number },
      _suggestions: { type: Array },

      /**
       * Right to left text and UI direction.
       */
      _rtl: { type: Boolean, attribute: 'rtl' },
    };
  }

  constructor() {
    super();
    this.active = false;
    this.inputHidden = false;
    this.suggestions = [];
    this.maxSuggestions = 8;
    this.minInput = 0;
    this.label = '';
    this.value = '';
    this.allowCustomValue = false;

    this._selectedItemIndex = -1;
    this._suggestions = [];

    // open the combo after suggestions with non-zero length arrive
    this._openOnDataReady = false;

    // Assign the function to the var to deregister the listeners correctly.
    this._boundClose = this.close.bind(this);

    this._boundOnScroll = this._onScroll.bind(this);
  }

  disconnectedCallback() {
    this.removeOutsideClickListener();
    this.removeOutsideScrollListener();
    super.disconnectedCallback();
  }

  firstUpdated() {
    this._dropdown = this.shadowRoot.getElementById('dropdown');
  }

  updated(changedProperties) {
    if (changedProperties.has('suggestions')) {
      this._prepareSuggestions();
      this._setDropdownSize();
      if (this._suggestions.length && this._openOnDataReady) {
        this._openOnDataReady = false;
      }
    }
  }

  addOutsideClickListener() {
    window.addEventListener('click', this._boundClose);
  }

  removeOutsideClickListener() {
    window.removeEventListener('click', this._boundClose);
  }

  addOutsideScrollListener() {
    this.parentElement.addEventListener('scroll', this._boundOnScroll);
  }

  removeOutsideScrollListener() {
    this.parentElement.removeEventListener('scroll', this._boundOnScroll);
  }

  blur() {
    this.shadowRoot.getElementById('input').blur();
  }

  clear() {
    this._clearValue();
    this._clearSuggestions();
  }

  close() {
    if (this.active) {
      this.collapse();
      this._clearSuggestions();
      this.dispatchEvent(new Event('close'));
      this.removeOutsideClickListener();
      this.removeOutsideScrollListener();
    }
  }

  collapse() {
    this.active = false;
  }

  expand() {
    if (!this._suggestions.length) {
      this._openOnDataReady = true;
      return;
    }

    this.active = true;

    this._setDropdownSize();
  }

  async focus() {
    await this.updateComplete;
    this.shadowRoot.getElementById('input').focus();
  }

  async open(evt) {
    if (!this.active) {
      if (this.inputHidden) return;
      this._prepareSuggestions();

      await this.updateComplete;

      // FIXME: Wait for the end of the input click event.
      setTimeout(() => {
        this.expand();
        this.focus();
        this.addOutsideClickListener();
        this.addOutsideScrollListener();
      }, 1);
    }
    // if handling a click, do not let it close the pop-up
    // via the global click handler
    else if (evt) {
      evt.stopPropagation();
    }
  }

  _clearValue() {
    this.value = '';
  }

  _clearSelectedItem() {
    this._selectedItemIndex = -1;
  }

  _clearSuggestions() {
    this._clearSelectedItem();
    this._suggestions = [];
  }

  _handleItemIndexSelection(next = true) {
    if (this._suggestions.length > 0) {
      if (next && this._selectedItemIndex + 1 < this._suggestions.length) {
        this._selectedItemIndex += 1;
      } else if (next) {
        this._selectedItemIndex = 0;
      } else if (!next && this._selectedItemIndex > 0) {
        this._selectedItemIndex -= 1;
      } else if (!next) {
        this._selectedItemIndex = this._suggestions.length - 1;
      }
    }
  }

  _handleSelectedItem(index) {
    if (index >= 0 && index < this._suggestions.length) {
      this.value = this._suggestions[index];
      this.dispatchEvent(
        new CustomEvent('change', {
          detail: {
            value: this._suggestions[index],
          },
        }),
      );
      this.close();
    }
  }

  _onInputInput(event) {
    event.stopPropagation();
    this.value = event.target.value;

    this.dispatchEvent(
      new CustomEvent('input', {
        detail: {
          value: event.target.value,
        },
      }),
    );
  }

  _onInputKeyDown(event) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.open();
      this._handleItemIndexSelection(event.key === 'ArrowDown');

      const suggestion = this.shadowRoot.querySelector(
        `.suggestion.index${this._selectedItemIndex}`,
      );
      this.shadowRoot
        .querySelector('.suggestions')
        .scrollTo(0, suggestion.offsetTop - 4);
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      if (this._selectedItemIndex === -1) this.close();
      else this._handleSelectedItem(this._selectedItemIndex);
    } else if (event.key === 'Escape') {
      this.close();
    }
  }

  _onInputKeyUp(event) {
    if (
      this.allowCustomValue &&
      !(
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter' ||
        event.key === 'Tab' ||
        event.key === 'Escape'
      )
    ) {
      this.value = event.target.value;
      this.dispatchEvent(
        new CustomEvent('change', {
          detail: {
            value: event.target.value,
          },
        }),
      );
    }
  }

  _prepareSuggestions() {
    // when there are no suggestions, suggestions is an empty array
    const suggestions = this.suggestions || [];
    this._suggestions = suggestions.slice(0, this.maxSuggestions);
  }

  async _setDropdownSize() {
    await this.updateComplete;
    this._dropdown.style.top = `${
      this.offsetTop -
      this._dropdown.offsetHeight -
      8 -
      this.parentElement.scrollTop
    }px`;
  }

  _onScroll() {
    this._setDropdownSize();
  }
}

window.customElements.define('ui-combo-box', UiComboBox);
