// The element is using [HLS.js](https://github.com/video-dev/hls.js) library that implements an HTTP Live Streaming client.
// The library works directly on top of a standard HTML video element and W3C Media Source Extensions.
// The library is from author of [Video.js Framework](https://github.com/videojs).
// JavaScript ecosystem has not better library for HTTP Live Streaming client.

import { css, html, LitElement } from 'lit';
import { customEvent } from '../utils/utils.js';
import { isSafari } from '../utils/browser.js';

import './ui-spinner.js';

const style = css`
  :host {
    display: block;
    position: relative;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    margin: 0 auto;
  }

  :host([squarePlayer]) .container {
    padding-top: calc(100% - var(--widget-gutter) * 2);
    width: calc(100% - var(--widget-gutter) * 2);
    margin-bottom: var(--widget-gutter);
  }

  #play {
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PGNpcmNsZSBmaWxsPSJ3aGl0ZSIgcj0iOSIgY3k9IjEyIiBjeD0iMTIiIC8+PHBhdGggZD0iTTEwIDE2LjVsNi00LjUtNi00LjV2OXpNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptMCAxOGMtNC40MSAwLTgtMy41OS04LThzMy41OS04IDgtOCA4IDMuNTkgOCA4LTMuNTkgOC04IDh6Ii8+PC9zdmc+);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30%;
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    display: none;
  }

  #player {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--widget-border-radius);
  }

  #thumbnail {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--tertiary-background-color);
    border-radius: var(--widget-border-radius);
  }

  /* move thumbnail under video during playing for resolve smaller video width than a parent */
  #thumbnail.hidden {
    z-index: -1;
  }
`;

/**
  Play a video from Cloudflare
*/
class UIVideoCloudflare extends LitElement {
  constructor() {
    super();

    /**
     * If `autoplay` is `true`, tells the browser to immediately start downloading the video and play it as soon as it can.
     */
    this.autoplay = false;

    /**
     * If `lightBox` is `true`, display the video full screen when clicked.
     */
    this.lightBox = false;

    /**
     * Cloudflare video id.
     */
    this.videoId = '';

    /**
     * Start of video, in seconds.
     */
    this.start = 0;

    /**
     * End of video, in seconds.
     */
    this.end = 0;

    /**
     * The length of video segment in seconds.
     * The segment is used for play a video at a random start time.
     * Default value is 0, this mean disabled randomization.
     */
    this.randomSegmentLength = 0;

    /**
     * Square size for video with aspect ration 1:1 or 9:16.
     */
    this.squarePlayer = false;

    /**
     * Cloudflare base URL.
     */
    this.cloudFlareBaseUrl = '';

    this._stats = {};

    this._canPlay = false;
    this._randomStartTime = null;
    this._isApplePlayer = isSafari;

    // HLS lib is needed on Android due this two issues:
    // 1. https://issues.chromium.org/issues/40462517
    // 2. https://stackoverflow.com/questions/22240408/setting-currenttime-for-html5-video-on-android-not-working-properly
    if (!this._isApplePlayer) {
      // HLS.js https://github.com/video-dev/hls.js
      // Fixed lib version to prevent change player API.
      // this._addScriptTag("https://unpkg.com/hls.js@1.1.2/dist/hls.min.js");
      this._addScriptTag('/node_modules/hls.js/dist/hls.min.js');
    }
  }

  static get styles() {
    return [style];
  }

  static get properties() {
    return {
      videoId: {
        type: String,
      },

      lightBox: {
        type: Boolean,
      },

      randomSegmentLength: {
        type: Number,
      },

      start: {
        type: Number,
      },

      end: {
        type: Number,
      },

      autoplay: {
        type: Boolean,
      },

      squarePlayer: {
        type: Boolean,
        reflect: true,
      },

      _spinnerVisible: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <div class="container">
        <video id="player"></video>
        <div id="thumbnail"></div>
        <ui-spinner ?visible=${this._spinnerVisible}></ui-spinner>
        <div id="play" @click="${this.play}"></div>
      </div>
    `;
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('videoId')) this._videoIdChanged(this.videoId);
  }

  firstUpdated() {
    this._stats.firstUpdatedAt = +new Date();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // pause the video before the element is removed from the DOM or moved to another place in the DOM
    this.pause();
  }

  async init() {
    await this.updateComplete;

    // ignore multiple init
    if (this._playButton) return;

    this.player = this.shadowRoot.getElementById('player');
    this._playButton = this.shadowRoot.getElementById('play');
    this._thumbnail = this.shadowRoot.getElementById('thumbnail');
    this._isHlsMediaAttached = false;

    this._initCurrentPlayer();

    // reset the player, set the video start time
    this.reset();
  }

  pause() {
    this.player.pause();
  }

  // set the video player size for correct video stream resolution
  setPlayerSize() {
    if (this.player) {
      this.player.setAttribute('width', this.player.offsetWidth);

      if (this.squarePlayer) {
        this.player.setAttribute('height', this.player.offsetWidth);
      } else {
        this.player.setAttribute('height', (this.player.offsetWidth / 16) * 9);
      } // 16:9
    }
  }

  // as widgets are animated in cards, it's not possible to
  // use position: fixed to get a fullscreen element ..
  // this is a hacky way to do that..
  _addLightboxTag() {
    // lightbox div
    const box = document.createElement('div');
    const container = document.createElement('div');

    box.appendChild(container);

    // move the video element to the lightbox
    container.appendChild(this.player);

    box.classList.add('lightbox-video');
    container.classList.add('container');

    if (this.squarePlayer) container.classList.add('square');

    const target = document.getElementsByTagName('body')[0];
    target.appendChild(box);

    this._lightBox = box;

    if (!document.head.querySelector('style#widget-video-cloudflare')) {
      this._addGlobalStyle();
    }
  }

  _addGlobalStyle() {
    this._globalStyle = document.createElement('style');
    this._globalStyle.id = 'widget-video-cloudflare';
    this._globalStyle.innerHTML = `
      .lightbox-video {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0,0,0,0.95);
      }
      .lightbox-video .container {
          --aspect-ratio: 9 / 16; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
          position: absolute;
          width: 100vh;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          padding-top: calc(var(--aspect-ratio) * 100%);
      }
      .lightbox-video .container.square {
          width: 57vh; /* ideal value for 9:16 */
          --aspect-ratio: 1; /* 1:1 Aspect Ratio */
      }
      .lightbox-video video {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 0;
          outline: none;
      }
      /* landscape mobile screen */
      /* https://stackoverflow.com/a/69289352/1614237 */
      @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        .lightbox-video .container {
          --aspect-ratio: 16 / 9; /* 16:9 Aspect Ratio */
          width: auto;
          height: 100vh;
          padding-top: initial;
          padding-right: calc(var(--aspect-ratio) * 100%);
        }
      }
    `;
    document.head.appendChild(this._globalStyle);
  }

  _addScriptTag(src) {
    // sync barrier
    if (window.__bh_imports) {
      // eslint-disable-next-line no-prototype-builtins
      if (window.__bh_imports.hasOwnProperty(src)) return;
    } else {
      window.__bh_imports = {};
      window.__bh_imports[src] = true;
    }

    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src = src;

    const target = document.getElementsByTagName('body')[0];
    target.appendChild(tag);
  }

  // returns a random number between min and max (both included)
  _getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  _setRandomStartTime() {
    // if the video duration is not known
    if (!this.player.duration) {
      this._startTime = Number(this.start);
      // eslint-disable-next-line no-console
      console.error('Video duration is not known, use static start time.');
      return;
    }

    const max =
      (Number(this.end) || this.player.duration) -
      Number(this.randomSegmentLength);

    if (!this._randomStartTime) {
      this._randomStartTime = this._getRandomInteger(Number(this.start), max);
    }

    this._startTime = this._randomStartTime;
  }

  _setStartTime() {
    try {
      this.player.currentTime = this._startTime;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        new Error(
          `Can't set video currentTime, startTime: ${this._startTime}, error: ${e.message}`,
        ),
      );
    }
  }

  // Get HLS manifest file
  // https://developers.cloudflare.com/stream/viewing-videos/using-own-player
  _getVideoUrl(videoId) {
    // The clientBandwidthHint url parameter removes all video representations
    // with a bitrate less than 0.1Mbps from the HLS (m3u8) manifest.
    // The resulting manifest contains only one stream with minimal resolution 426x240px,
    // which is ideal for small video in the widget with max width 400px.
    // First stream segment has small size (etc 200KB) instead of size etc 1.7MB (1280x720px).
    // The small segment is suitable for slow mobile connection.
    let clientBandwidthHint = '?clientBandwidthHint=0.1';

    // The lightBox view uses video html tag with max size 1280x720px on desktop.
    // Use default HLS manifest with video resolutions 1280x720px,
    // 1920x1080px, 854x480px, 640x360px, 426x240px.
    if (this.lightBox && this.player.offsetWidth > 400) {
      clientBandwidthHint = '';
    }

    return `${this.cloudFlareBaseUrl}/${videoId}/manifest/video.m3u8${clientBandwidthHint}`;
  }

  _setVideoThumbnail(videoId, time) {
    // https://developers.cloudflare.com/stream/viewing-videos/displaying-thumbnails
    const url = `${this.cloudFlareBaseUrl}/${videoId}/thumbnails/thumbnail.jpg?width=800&time=${time}s`;
    this._thumbnail.style.backgroundImage = `url(${url})`;
  }

  _initCurrentPlayer() {
    // loop until the script was loaded, templates stamped and src set
    if (
      (!this._isApplePlayer && !window.Hls) ||
      !this.player ||
      !this.videoId
    ) {
      setTimeout(() => this._initCurrentPlayer(), 200);
      return;
    }

    // to avoid double initialization while player waits for async code
    if (this._hls) return;

    this._stats.videoLoadDelay = +new Date() - this._stats.firstUpdatedAt;

    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video load delay',
          value: this._stats.videoLoadDelay,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );

    if (this.autoplay) {
      this.player.setAttribute('muted', '');
      // muted property is accepted on browser with hls.js
      this.player.muted = true;
    }

    // When Data Saver is enabled, Chrome forces the preload value to none.
    // On a cellular connection (2G, 3G, and 4G), Chrome forces the preload value to metadata.
    // https://web.dev/fast-playback-with-preload/
    this.player.setAttribute('preload', 'none');
    this.player.preload = 'none';

    this.setPlayerSize();

    // On iOS, <video playsinline> elements will now be allowed to play inline,
    // and will not automatically enter fullscreen mode when playback begins.
    // https://stackoverflow.com/a/48249402/1614237
    this.player.setAttribute('playsinline', '');

    // the media has reach the end
    this.player.addEventListener('ended', this._onPlayerEnded.bind(this));

    // a file can be played all the way to the end without pausing for buffering
    // this.player.addEventListener('canplaythrough', this._onPlayerCanPlay);

    // The canplay event is fired when the user agent can play the media,
    // but estimates that not enough data has been loaded to play the media
    // up to its end without having to stop for further buffering of content.
    this.player.addEventListener('canplay', this._onPlayerCanPlay.bind(this));

    this.player.addEventListener(
      'loadedmetadata',
      this._onLoadedMetadata.bind(this),
    );

    this.player.addEventListener('loadeddata', this._onLoadedData.bind(this));

    this.player.addEventListener('waiting', this._onWaiting.bind(this));

    // this.player.addEventListener('error', function(e) {
    //     console.log(e);
    // });

    this.player.addEventListener(
      'timeupdate',
      this._onPlayerTimeUpdate.bind(this),
    );

    const videoSrc = this._getVideoUrl(this.videoId);

    if (window.Hls?.isSupported()) {
      // https://github.com/video-dev/hls.js/blob/master/docs/API.md
      this._hls = new window.Hls();
      this._hls.attachMedia(this.player);

      // https://github.com/video-dev/hls.js/blob/master/docs/API.md#third-step-load-a-manifest
      this._hls.on(window.Hls.Events.MEDIA_ATTACHED, () => {
        // this._hls.on(window.Hls.Events.LEVEL_LOADED, function (event, data) {
        //     console.log(data.details.totalduration);
        // });

        this._isHlsMediaAttached = true;

        this._hls.loadSource(videoSrc);
      });
    }
    // HLS.js is not supported on platforms that do not have Media Source
    // Extensions (MSE) enabled.
    //
    // When the browser has built-in HLS support (check using `canPlayType`),
    // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
    // element through the `src` property. This is using the built-in support
    // of the plain video element, without using HLS.js.
    //
    // Note: it would be more normal to wait on the 'canplay' event below however
    // on Safari (where you are most likely to find built-in HLS support) the
    // video.src URL must be on the user-driven white-list before a 'canplay'
    // event will be emitted; the last video event that can be reliably
    // listened-for when the URL is not on the white-list is 'loadedmetadata'.
    else if (this._isApplePlayer) {
      this._hls = true;
      this.player.src = videoSrc;
      this.player.load();
    } else {
      this.shadowRoot.querySelector('.container').innerHTML =
        '<p>Video nelze přehrát.</p>';
    }
  }

  _videoIdChanged(newId) {
    if (
      !newId ||
      // check if HLS lib is not loaded
      (!this._isApplePlayer && !window.Hls) ||
      // check if HLS lib media is not attached, the media is needed for this._hls.loadSource()
      (!this._isApplePlayer && window.Hls && !this._isHlsMediaAttached) ||
      !this.player
    ) {
      return;
    }

    this._randomStartTime = null;
    this._canPlay = false;
    this._spinnerVisible = true;

    // reset stats
    this._stats = { firstUpdatedAt: +new Date() };

    const videoSrc = this._getVideoUrl(newId);

    if (window.Hls?.isSupported() || this._isApplePlayer) {
      // random start time is waiting for video duration, later generated thumbnail is not important
      // eslint-disable-next-line eqeqeq
      if (this.randomSegmentLength == 0) {
        this._setVideoThumbnail(this.videoId, this.start);
      }
    }

    if (window.Hls?.isSupported()) {
      this._hls.loadSource(videoSrc);
    } else if (this._isApplePlayer) {
      this.player.src = videoSrc;
      this.player.load();
    }

    // reset the player, set the video start time
    this.reset();
  }

  play() {
    this._stats.startTime = +new Date() - this._stats.firstUpdatedAt;
    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video start time',
          value: this._stats.startTime,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );

    if (this.lightBox && !this._lightBox) {
      // add own lightbox instance for each widget
      this._addLightboxTag();
    }

    if (this.lightBox) {
      this._lightBox.style.opacity = '1';
      this._lightBox.style.visibility = 'visible';
    }

    this._playButton.style.display = 'none';
    this._thumbnail.classList.add('hidden');

    this.player.play();

    this.dispatchEvent(new Event('play'));
  }

  _onLoadedMetadata() {
    this._stats.loadedMetadataTime = +new Date() - this._stats.firstUpdatedAt;

    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video metadata load delay',
          value: this._stats.loadedMetadataTime,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );

    // only for random start time
    // the video duration is known now, we can set the random start time and thumbnail
    if (this.randomSegmentLength) {
      this._setRandomStartTime();
      this._setVideoThumbnail(this.videoId, this._startTime);
    }
  }

  _onLoadedData() {
    this._stats.loadedDataTime = +new Date() - this._stats.firstUpdatedAt;

    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video data load delay',
          value: this._stats.loadedDataTime,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );
  }

  _onWaiting() {
    this._stats.waitingTime = +new Date() - this._stats.firstUpdatedAt;
  }

  // this function is called when video buffer contains about 4 seconds
  // new video start time will run this function again
  _onPlayerCanPlay() {
    // var bufferedSeconds = this.player.buffered.end(0) - this.player.buffered.start(0);
    // console.log(bufferedSeconds);

    // ignore multiple video canPlay events
    if (this._stats.canPlayTime) return;

    this._stats.canPlayTime = +new Date() - this._stats.firstUpdatedAt;

    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video can play time',
          value: this._stats.canPlayTime,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );

    // set the video start time for random start time, we know start time from video duration
    if (this.randomSegmentLength) {
      this._setStartTime();
    }

    // wait for unbusy main thread
    requestAnimationFrame(() => {
      this._spinnerVisible = false;

      if (!this.autoplay) this._playButton.style.display = 'block';

      this.dispatchEvent(new Event('canplay'));

      this._canPlay = true;
    });
  }

  /**
   * mark question as answered by setting some valid answer
   */
  _onPlayerEnded() {
    this._stats.endTime = +new Date() - this._stats.firstUpdatedAt;
    this.dispatchEvent(
      customEvent(
        'log',
        {
          ts: +new Date(),
          type: 'debug',
          name: 'video end time',
          value: this._stats.endTime,
          category: 'timing',
          source: this._getVideoUrl(this.videoId),
          widget: { id: this.parent.id, name: this.parent.name },
        },
        true,
      ),
    );

    const answer = {
      startTime: this._startTime,
      videoDuration: this.player.duration,
    };
    answer.stats = this._stats;

    this.dispatchEvent(new CustomEvent('ended', { detail: { answer } }));
  }

  _onPlayerTimeUpdate() {
    // console.log('currentTime', this.player.currentTime);

    if (this.randomSegmentLength === 0 && this.end === 0) return;

    const randomFinished =
      this.randomSegmentLength &&
      // 1 + '1' = '11'
      this.player.currentTime >=
        this._startTime + Number(this.randomSegmentLength);

    const startEndFinished = this.end && this.player.currentTime >= this.end;

    if (randomFinished || startEndFinished) {
      this.pause();
      this._onPlayerEnded();
    }
  }

  /**
   * re-init the player for next click
   */
  reset() {
    this.hideLightBox();

    // show the thumbnail
    this._thumbnail.classList.remove('hidden');

    // if the video is ready to play from previous play
    if (this._canPlay) {
      if (!this.autoplay) this._playButton.style.display = 'block';
    } else {
      this._playButton.style.display = 'none';
      this._spinnerVisible = true;
    }

    // for static start time only, random start time is waiting for video duration
    if (Number(this.randomSegmentLength) === 0) {
      this._startTime = Number(this.start);

      // if the video is ready to play from previous play
      if (this._canPlay) {
        // hide the thumbnail
        this._thumbnail.classList.add('hidden');
      } else {
        this._setVideoThumbnail(this.videoId, this._startTime);
      }

      this._setStartTime();
    }
  }

  /**
   * remove all DOM elements
   */
  cleanDOM() {
    if (this._globalStyle) this._globalStyle.remove();
    if (this._lightBox) {
      this._lightBox.remove();
      this._lightBox = null;
    }
  }

  /**
   * hide lightBox
   */
  hideLightBox() {
    if (this._lightBox) {
      this._lightBox.style.opacity = '0';
      this._lightBox.style.visibility = 'hidden';
    }
  }
}

window.customElements.define('ui-video-cloudflare', UIVideoCloudflare);
