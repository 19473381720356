// ## Question stage

// `qset-questions` implements a 'stage' for widgets:

// - skip/next buttons
// - space for the widget
// - condition evaluator

import { html, css, LitElement } from 'lit';
import * as condsl from '@behavio/bh-dsl/condsl';
import * as dsl from '@behavio/bh-dsl';

import {
  customEvent,
  throttleGate,
  forwardCache,
  now,
  macrotask,
} from './utils/utils.js';
import { SharedStyles } from './styles/shared-styles.js';

import { isIE, isOldEdge, onIOS } from './utils/browser.js';

import './widget-buttons.js';
import './widget-chips.js';
import './widget-outro.js';
import './widget-grid.js';
import './widget-emotion.js';
import './widget-image.js';
import './widget-input.js';
import './widget-input-chips.js';
import './widget-list.js';
import './widget-pairs.js';
import './widget-random-pairs.js';
import './widget-quiz-answers.js';
import './widget-scale.js';
import './widget-slider.js';
import './widget-video.js';
import './widget-video-cloudflare.js';
import './widget-video-emotion.js';
import './ui-parts/ui-spinner.js';
import './stage-parts/stage-card.js';
import './stage-parts/stage-simple-card.js';
import './stage-parts/stage-buttons.js';
import './stage-parts/stage-card-stack.js';

const style = css`
  :host {
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 100%;
  }

  .spinner-global {
    position: initial;
    height: 500px;
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    :host {
      overflow: hidden;
    }
  }

  @media screen and (min-width: 600px) and (min-height: 700px) {
    :host {
      padding-top: 24px;
    }
  }
`;

class QsetQuestions extends LitElement {
  constructor() {
    super();

    this.userInfo = {};
    this.answers = {};
    this.currentQuestionIndex = undefined;
    this.resumeData = {};
    this.lastQuestionIndex = -1;
    this._validAnswer = {};
    this._widgetTasks = {};
    this._qsetProgress = 0;
    this._selectedAction = 'none';
    this._previousQuestionIndex = -1;
    this._animationDisabled = false;

    // throttle gate with 500ms wait time
    this._throttleGate = throttleGate(500);

    this.addEventListener('widget-advance-question', this._advanceQuestion);
    this.addEventListener('widget-ready', this._widgetReady);
    this.addEventListener('exit-stage', this._exitStage);
    this.addEventListener('show-next-action-button', this._showNextButton);
    this.addEventListener('show-skip-action-button', this._showSkipButton);
    this.addEventListener('hide-action-button', this._hideActionButton);
    this.addEventListener(
      'set-next-action-button-text',
      this._setNextButtonText,
    );
    this.addEventListener('start-countdown', this._onStartCountdown);
    this.addEventListener('scroll-to-bottom', this._scrollToBottom);
    this.addEventListener('finish-qset', this._finishQset);
  }

  static get styles() {
    return [SharedStyles, style];
  }

  static get properties() {
    return {
      /**
       * Questions that will be displayed.
       *
       * @type {[{id: string, type: string, question: string, _widget_dependent_}]}
       */
      questions: {
        type: Array,
      },

      /**
       * Contains labels and other additonal user specific info.
       *
       * @type {{set_id: {key: value}}}
       */
      userInfo: {
        type: Object,
      },

      /**
       * Holds the answers.
       *
       * @type {{id: {value: _widget_dependent_, time_spent: seconds,
       * real_index: _position_in_randomized_questionaire_, additional_data: _widget_dependent_ }}}
       */
      answers: {
        type: Object,
      },

      /**
       * Questions set.
       */
      qset: {
        type: Object,
      },

      /**
       * Current question index. Use to switch widgets, work is done by `_questionsChanged`.
       */
      currentQuestionIndex: {
        type: Number,
      },

      /**
       * If trying to continue in a previously started set, set the index of last filled question.
       */
      lastQuestionIndex: {
        type: Number,
      },

      /**
       * Resume data from previous qset state.
       */
      resumeData: {
        type: Object,
      },

      // Current question.
      _currentQuestion: {
        type: Object,
      },

      /**
       * The current widget.
       */
      _currentWidget: {
        type: Object,
      },

      /**
       * If the current widget has a valid answer, it's propagated to
       * this property.
       */
      _validAnswer: {
        type: Object,
      },

      /**
       * Holds the number of 'approximate tasks' that the user
       * must perform in each widget. Keyed on widget id,
       * so the progress can be updated accordingly, if some widgets can be skipped.
       * Also used as a semaphore for the initialization (each widget reports the
       * number of tasks when initialized).
       *
       * @type {{widget_slug: _number_of_tasks_}}
       */
      _widgetTasks: {
        type: Object,
      },

      /**
       * Current progress, displayed in number and in progressbar.
       */
      _qsetProgress: {
        type: Number,
      },

      /**
       * Chooses current action button.
       */
      _selectedAction: {
        type: String,
      },

      /**
       * Time when the current widget entered stage [miliseconds].
       */
      _enteredStageTime: {
        type: Number,
      },

      /**
       * Question to go back to, -1 means back button is disabled.
       */
      _previousQuestionIndex: {
        type: Number,
      },

      _animationDisabled: {
        type: Boolean,
      },

      _buttonsDisabled: {
        type: Boolean,
      },

      _card1Disabled: {
        type: Boolean,
      },

      _nextButtonLabel: {
        type: String,
      },
    };
  }

  render() {
    return html`
      <stage-card-stack
        ?animationDisabled="${this._animationDisabled}"
        .questions="${this.questions}"
        @current-widget-changed="${this._currentWidgetChanged}"
        @current-widget-entered="${this._onCurrentWidgetEntered}"
        @new-stack-animation-ended="${this._onNewStackAnimationEnded}"
      >
        <stage-simple-card
          class="card secondary scrollbar"
          id="card3"
          slot="card3"
        ></stage-simple-card>

        <stage-simple-card
          class="card secondary scrollbar"
          id="card2"
          slot="card2"
        >
        </stage-simple-card>

        <stage-card
          class="card scrollbar"
          id="card1"
          slot="card1"
          ?disabled="${this._card1Disabled}"
          ?animationDisabled="${this._animationDisabled}"
          @countdown-ended="${this._onCountdownEnded}"
          @countdown-last-tick="${this._onCountdownLastTick}"
        >
        </stage-card>

        <ui-spinner class="spinner-global" slot="globalSpinner"></ui-spinner>
      </stage-card-stack>

      <stage-buttons
        hidden
        activeAction="${this._selectedAction}"
        nextButtonLabel="${this._nextButtonLabel}"
        ?disabled="${this._buttonsDisabled}"
        ?showBackButton="${this._canGoBack(this._previousQuestionIndex)}"
        @back-button-click="${this._goBack}"
        @next-button-click="${this._nextQuestion}"
        @skip-button-click="${this._skipQuestion}"
      ></stage-buttons>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('questions')) {
      this._questionsChanged(this.questions);
    }

    // show the background after selected action is changed or canGoBack is changed
    if (
      changedProperties.has('_selectedAction') ||
      (changedProperties.has('_previousQuestionIndex') &&
        this._canGoBack(this._previousQuestionIndex))
    ) {
      this._showStageButtonsBackground();
    }
  }

  /**
   * "Output" event.
   *
   * Fired when all the questions (given conditons) were answered.
   *
   * @event finish-qset
   */

  /**
   * "In/out" event.
   *
   * Fired when all the widgets are loaded and initialized.
   *
   * @event widgets-ready
   */
  firstUpdated() {
    this._card1 = this.shadowRoot.getElementById('card1');
    this._card2 = this.shadowRoot.getElementById('card2');
    this._card3 = this.shadowRoot.getElementById('card3');

    this._animationDisabled = window._animationDisabled || isIE || isOldEdge;

    if (this._animationDisabled) {
      this._card2.remove();
      this._card3.remove();
      this._card1.classList.add('shadow');
    }

    this._buttons = this.shadowRoot.querySelector('stage-buttons');
    this._cardStack = this.shadowRoot.querySelector('stage-card-stack');
    this._cardStack._initWidget = this._initWidget.bind(this);

    window.addEventListener('resize', () => {
      this._showStageButtonsBackground();
    });

    this._showStageButtonsBackgroundBind =
      this._showStageButtonsBackground.bind(this);

    window.addEventListener('scroll', this._showStageButtonsBackgroundBind);

    window.addEventListener('keydown', this._showStageButtonsBackgroundBind);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('scroll', this._showStageButtonsBackgroundBind);
    window.removeEventListener('keydown', this._showStageButtonsBackgroundBind);
  }

  /**
   * Map questions `type` to widget name.
   */
  _typeToElement(type) {
    // a set of widgets where simple mapping
    // 'type' -> 'widget-\(type)' will work
    // there is no builtin set() in JS, use {name:1}
    const simpleWidgets = {
      grid: 1,
      image: 1,
      list: 1,
      buttons: 1,
      input: 1,
      'input-chips': 1,
      slider: 1,
      'video-player': 1,
      'quiz-answers': 1,
      emotion: 1,
      chips: 1,
      outro: 1,
      pairs: 1,
      scale: 1,
      upload: 1,
      'flow-snooze': 1,
      'flow-signin': 1,
      video: 1,
      'video-cloudflare': 1,
      'video-emotion': 1,
      'random-pairs': 1,
    };
    if (type in simpleWidgets) return `widget-${type}`;

    // type mapping - when a widget implements more types
    // or used for legacy compatibility
    const mappedWidgets = {};

    if (type in mappedWidgets) return mappedWidgets[type];

    // return dummy widget by default
    return 'widget-dummy';
  }

  _showStageButtonsBackground() {
    // don't show the background if there is no selected action and the back button is not shown
    if (this._selectedAction === 'none' && !this._buttons.showBackButton) {
      return;
    }

    this._buttons.hasBackground = this._doElementsCollide(
      this._card1,
      this._buttons,
    );
  }

  // https://stackoverflow.com/a/9607413/1614237
  _doElementsCollide(el1, el2) {
    const el1OffsetBottom = el1.offsetTop + el1.offsetHeight;
    const el1OffsetRight = el1.offsetLeft + el1.offsetWidth;
    const el2OffsetBottom = el2.offsetTop + el2.offsetHeight;
    const el2OffsetRight = el2.offsetLeft + el2.offsetWidth;

    return !(
      el1OffsetBottom < el2.offsetTop ||
      el1.offsetTop > el2OffsetBottom ||
      el1OffsetRight < el2.offsetLeft ||
      el1.offsetLeft > el2OffsetRight
    );
  }

  /**
   * Evaluate `condition` written in the 'condition DSL' against
   * current environment (answers, userInfo).
   * `condition` - String, the DSL is described in
   *   [WidgetBehavior](#TrendaroBehaviors.WidgetBehavior)
   */
  evalCondition(condition) {
    const parsed = dsl.loads(condition)[0];
    return condsl.evaluate(parsed, this.getValue.bind(this));
  }

  /**
   * Get value by a 'fully classified' name, like `self.piggy_lover` or
   * `info.labels`. Used by the rule engine and provided to widgets as
   * an API.
   */
  getValue(name) {
    // name is like self.likes_animals
    const keys = name?.split('.');
    const base = keys[0];
    // const valkey = keys.slice(1).join(".");
    const valkey = keys[1];

    function safeGet(dict, n) {
      const val = dict[n];
      if (val === undefined) {
        // report the full name through closure
        // eslint-disable-next-line no-console
        console.warn(
          `Condition referenced undefined variable: ${name}`,
          'available keys:',
          Object.keys(dict),
        );
      }
      return val;
    }

    // handle rich answer format
    if (base === 'self') {
      const rich = safeGet(this.answers, valkey);
      if (rich === undefined || !rich.value) return rich;

      // Support for subkeys .. we don't use them much now`
      if (keys.length > 2) return rich.value[keys[2]];

      // question type dependent formats
      // be careful with the dot chaining..
      if (this.questions) {
        const question = this.questions.find(q => q.id === valkey);

        // emotion: by default access emotion values without explicit addressing
        if (question && question.type === 'emotion') return rich.value.emotions;

        // chips with buttons: encode answers as chip:button ids
        if (question && question.type === 'pairs' && rich.value.length > 0) {
          return rich.value.map(item => `${item.chip}:${item.button}`);
        }
      }

      return rich.value;
    }

    // handle rich qset format
    if (base === 'qset') {
      const rich2 = safeGet(this.qset, valkey);
      if (rich2 === undefined) return rich2;

      return rich2;
    }

    const userDict = this.userInfo[base];
    if (userDict === undefined) {
      // eslint-disable-next-line no-console
      console.error(`Base key missing in userInfo: ${name}`);
      return '';
    }

    return safeGet(userDict, valkey);
  }

  _currentWidgetChanged(event) {
    this._currentWidget = event.detail;
  }

  _onCurrentWidgetEntered() {
    // store the time
    this._enteredStageTime = now();
  }

  /**
   * Reset the state machine to a 'brand new' condition.
   */
  _resetQset() {
    this._currentWidget = undefined;
    this.currentQuestionIndex = undefined;
    this._currentTransition = Promise.resolve();
    this._previousQuestionIndex = -1;
    this._validAnswer = {};
    this._widgetTasks = {};
    this._qsetProgress = 0;

    // Reset answers only without saved data from back-end.
    if (this.lastQuestionIndex === -1) this.answers = {};
    else this.answers = this.resumeData;

    this._cardStack.resetCards();
  }

  /**
   * Convert QSet settings to a 'live' widget.
   */
  _initWidget(newQuestion) {
    const widget = document.createElement(
      this._typeToElement(newQuestion.type),
    );

    // propagate special properties
    // get mediaBaseUrl from front matter of qset or storage_prefix from Behavio Go config
    widget._mediaBaseUrl =
      this.qset?.front_matter?.mediaBaseUrl || this.qset.mediaBaseUrl;

    // get cloudFlareBaseUrl from front matter of qset or cloudflare_base_url from Behavio Go config
    widget._cloudFlareBaseUrl =
      this.qset?.front_matter?.cloudFlareBaseUrl || this.qset.cloudFlareBaseUrl;

    // get name of host app
    widget._hostApp = this.qset.hostApp;

    widget._theme = this.qset.theme;
    widget._evalCondition = this.evalCondition.bind(this);
    widget._getValue = this.getValue.bind(this);

    // propagate properties from the config object
    Object.keys(newQuestion).forEach(key => {
      widget[key] = newQuestion[key];
    });

    return widget;
  }

  /**
   * Questions arrived, reset the state of the widget,
   * and add widgets for all questions.
   */
  _questionsChanged(newQuestions) {
    this._resetQset();

    // create 3 widgets in advance
    // we'd like to pre-load widgets, especially images
    // images should pre-load when a widget will be placed in DOM
    // disregarding if they're in DOM (if not, we can later append to DOM)
    this._cardStack._widgetCache = forwardCache(
      newQuestions,
      this._initWidget.bind(this),
      3,
    );

    this._nextButtonLabel = this.qset?.front_matter?.confirmButtonText || 'ok';

    // if we ever needed to do conditions on the first, use find.. here
    // this also enables continue from last time, if lastQuestionIndex is set (default is -1)
    const firstQuestion = this._findNextQuestion(this.lastQuestionIndex);

    this._currentTransition.then(() => {
      this._currentTransition = this._switchToQuestion(firstQuestion);
    });

    // notify minute-qset that widgets are ready
    this.dispatchEvent(customEvent('widgets-ready'));
  }

  /**
   * Check if the current question has a parsable condition
   * and if it is fulfilled.
   */
  _checkCondition(qObj) {
    // questions without conditions are always on
    if (!('condition' in qObj) || qObj.condition === '') return true;

    return this.evalCondition(qObj.condition);
  }

  /**
   * Look for the next question to display starting with `currentIdx`.
   * Remove the tasks associated with the skipped questions.
   */
  _findNextQuestion(currentIdx) {
    for (let next = currentIdx + 1; next < this.questions.length; next += 1) {
      const nextQuestion = this.questions[next];
      if (this._checkCondition(nextQuestion)) return next;

      // the question was skipped, remove it's tasks
      this._completeTasks(nextQuestion.id);
    }

    return -1;
  }

  _resetStage() {
    // Close (potential) keyboard on mobile.
    document.activeElement.blur();

    // reset joystick
    //
    this._buttonsDisabled = true;
    this._nextButtonLabel = this.qset?.front_matter?.confirmButtonText || 'ok';
    this._selectedAction = 'none';
  }

  /**
   * Get answer from the current widget/question.
   */
  _getAnswer() {
    const timeSpent = now() - this._enteredStageTime;

    //
    // store current answer
    // store the index because of (possible) randomization
    //
    const response = {
      value: this._currentWidget.answer,
      time_spent: Math.round(timeSpent),
      real_index: this.currentQuestionIndex,
    };

    // store additional data if present
    if (this._currentWidget.additionalData) {
      response.additional_data = this._currentWidget.additionalData;
    }

    // console.log(response,
    //             "values" in this._currentWidget,
    //             "randomizeRecipe" in this._currentWidget,
    //             this._currentWidget.randomizeRecipe);

    // if values could have been randomized, store the resulting ids
    if (
      'values' in this._currentWidget &&
      'randomizeRecipe' in this._currentWidget &&
      this._currentWidget.randomizeRecipe
    ) {
      response.real_options = this._currentWidget.values.map(x => x.id);
    }

    return response;
  }

  /**
   * Store answer of the current widget/question.
   */
  _saveAnswer() {
    this.answers[this._currentQuestion.id] = this._getAnswer();

    this.dispatchEvent(customEvent('answers-changed', this.answers, true));
  }

  /**
   * Fire `qset-finished` event after receiving `finish-qset` from the current widget.
   * Attach the answer to the event for store to the backend.
   * Outro widget sends `finish-qset` event for store the answer and set is_complete of qset to true.
   */
  _finishQset() {
    this.dispatchEvent(
      customEvent(
        'qset-finished',
        { answer: this._getAnswer(), questionID: this._currentQuestion.id },
        true,
      ),
    );
  }

  /**
   * advance question comprises
   * - throttling, as it is the front line from the clicks and (dumb) widgets
   * - storing current answer
   * - finding the next question to display, finishing the set if none is found
   * - cleaning the stage and displaying the question
   */
  _advanceQuestion(event) {
    // stop the event from bubbling up here, we're handling it here
    event.stopPropagation();

    // limit how often advanceQuestion can be called to half a second
    // throttle invocations (double click on a single button)
    if (!this._throttleGate()) return;

    // Stop countdown timer of previous widget.
    this._card1.stopCountdown();

    // prevent clicks on cards during question change
    // keep it here to prevent mixing with the animation
    // (will see if that's the best idea)
    this._preventClicks();

    // save the answer and dispatch `answers-changed` event to parent (Behavio Go)
    this._saveAnswer();

    // dispatch `advance-question` event to parent (Behavio Go)
    this.dispatchEvent(customEvent('advance-question', event.detail, true));

    // dispatch `exit-stage` event to the current / exiting widget
    this._leaveCurrentQuestion();

    //
    // advance question
    //
    const nextq = this._findNextQuestion(this.currentQuestionIndex);

    // check if we reached the end of the qset
    if (nextq === -1) {
      // wait for micro task of advance-question event
      // keep order of events: 1. advance-question, 2. finish-qset or qset-dismissed
      setTimeout(() => {
        // if finishing with a widget that is not an outro,
        // send 'finish-qset' only after the answer is known
        // (outro sends it's own finish to reduce the chance that
        //  users close the window before data is saved..)
        if (this._currentQuestion.type !== 'outro') {
          this.dispatchEvent(new Event('finish-qset'));
        }

        this.dispatchEvent(new Event('qset-dismissed'));
      });
      return;
    }

    // outro with screenOut prop and action button stops the qset
    if (
      this._currentQuestion.type === 'outro' &&
      this._currentWidget.screenOut
    ) {
      // wait for micro task of advance-question event
      // keep order of events: 1. advance-question, 2.qset-dismissed
      setTimeout(() => {
        this.dispatchEvent(new Event('qset-dismissed'));
      });
      return;
    }

    // handle back button for the next question
    // TODO: add more contitions
    if (this._currentQuestion.type !== 'outro') {
      this._previousQuestionIndex = this.currentQuestionIndex;
    } else {
      // disable the go back button
      this._previousQuestionIndex = -1;
    }

    // no going back if current widget is outro
    // as the questionnaire is already locked if at the last outro
    const newQuestion = this.questions[nextq];
    if (newQuestion.type === 'outro') this._previousQuestionIndex = -1;

    // wait for current transition to finish before starting a new one
    this._currentTransition.then(() => {
      this._currentTransition = this._switchToQuestion(nextq);
    });
  }

  _canGoBack(prevQuestion) {
    return prevQuestion !== -1 && this.qset?.front_matter?.goBack;
  }

  /**
   * Handle click on the 'back' button.
   */
  _goBack() {
    // throttle clicks on the back button
    if (!this._throttleGate()) return;

    // if we accidentally got here but going back is forbidden
    if (this._previousQuestionIndex === -1) return;

    this._card1.stopCountdown();

    this._leaveCurrentQuestion();

    // wait for current transition to finish before starting a new one
    // use closure to keep the value
    const prevQ = this._previousQuestionIndex;
    this._currentTransition.then(() => {
      this._currentTransition = this._switchToQuestion(prevQ);
    });

    // disable any more going back
    this._previousQuestionIndex = -1;

    // dispatch event to inform the qset that we are going back
    // Behavio Go will handle scroll to the top on small mobile screens
    this.dispatchEvent(customEvent('go-back', null, true));
  }

  /**
   * Inform current widget of stage change and reset the stage.
   */
  _leaveCurrentQuestion() {
    // send event to the exiting widget
    this._currentWidget.dispatchEvent(
      customEvent('exit-stage', { id: this._currentQuestion.id }),
    );

    // reset buttons and keyboard focus
    this._resetStage();
  }

  /**
   * Choose appropriate animation, according to currentQuestionIndex
   * and questionIndex and perform the transition. Respect disabled animations.
   */
  _switchToQuestion(questionIndex) {
    if (questionIndex >= this.questions.length) {
      // eslint-disable-next-line no-console
      console.error(
        'qset-questions: questionIndex too big',
        questionIndex,
        '>=',
        this.questions.length,
      );
      return;
    }

    let localTransition;

    if (questionIndex < this.currentQuestionIndex) {
      localTransition = this._cardStack.animateFlickBack(questionIndex);
    }
    // starting on a first question
    else if (
      this._currentWidget === undefined ||
      // if there are more cards beyond current outro
      // we're faking a new qset, suitable for the intro on start only
      (this._currentQuestion?.type === 'outro' && questionIndex === 1)
    ) {
      // if there is no last question
      if (this.lastQuestionIndex === -1) {
        // fly the cards in from the right
        localTransition = this._cardStack.animateNewStack(questionIndex);
      }
      // show the cards without animation
      else {
        localTransition = this._cardStack.showNewStack(questionIndex);
      }
    }
    // everything else is just a flicking off the top card to the left
    else {
      localTransition = this._cardStack.animateFlickLeft(questionIndex);
    }

    // update current question after using it in the animation
    this.currentQuestionIndex = questionIndex;
    this._currentQuestion = this.questions[questionIndex];

    // return a promise that resolves when the animation is done
    // and width of current widget is known
    // eslint-disable-next-line consistent-return
    return (
      localTransition
        // TODO(josef): remove this hack
        .then(
          () =>
            new Promise(resolve => {
              // wait for the width of the current widget to be known
              // this is needed for the progress border
              const loop = () =>
                this._currentWidget?.offsetWidth !== 0
                  ? resolve()
                  : setTimeout(loop, 100);
              loop();
            }),
        )
        .then(() => {
          // enable clicks after end of animation
          this._enableClicks();

          return Promise.resolve();
        })
    );
  }

  /**
   * Function to be called when cards animation ends.
   */
  _onNewStackAnimationEnded() {
    // show the buttons after the widget is ready
    // (we don't want to show the buttons before the widget is shown)
    this._buttons.removeAttribute('hidden');

    // check condition to showing the background after the widget is ready
    this._showStageButtonsBackground();
  }

  /**
   * Fire `next-clicked` event on the current widget.
   */
  _nextQuestion() {
    this._currentWidget.dispatchEvent(customEvent('next-clicked'));
  }

  /**
   * Fire `skip-clicked` event on the current widget.
   */
  _skipQuestion() {
    this._currentWidget.dispatchEvent(customEvent('skip-clicked'));
  }

  _setNextButtonText(event) {
    this._nextButtonLabel = event.detail;
  }

  _showNextButton() {
    this._buttonsDisabled = false;
    this._selectedAction = 'next';
    if (
      this._currentQuestion.type.indexOf('input') === -1 &&
      !(
        this._currentQuestion.type === 'emotion' &&
        this._currentWidget.showQuestion
      )
    ) {
      this._buttons.focusActiveButton();
    }

    const isOpenedKeyboard = window.visualViewport.height < this.clientHeight;

    // scroll to bottom after show the button on iOS only
    // issue https://github.com/behavio/minute-qset/issues/544
    if (onIOS && isOpenedKeyboard) {
      const actionButtonPadding = Number(
        getComputedStyle(this)
          .getPropertyValue('--action-button-padding')
          .replace('px', ''),
      );
      const actionButtonSize = Number(
        getComputedStyle(this)
          .getPropertyValue('--action-button-size')
          .replace('px', ''),
      );

      // scroll to the bottom of this element + action button height on iOS with opened keyboard
      // opened keyboard add white space on the bottom of the screen, this is bug in iOS
      // more info:
      // https://medium.com/@krutilin.sergey.ks/fixing-the-safari-mobile-resizing-bug-a-developers-guide-6568f933cde0
      // https://stackoverflow.com/questions/56351216/ios-safari-unwanted-scroll-when-keyboard-is-opened-and-body-scroll-is-disabled
      const top =
        this.clientHeight -
        window.visualViewport.height +
        actionButtonSize +
        2 * actionButtonPadding;

      this._scrollToBottom(top);
    }
  }

  _showSkipButton() {
    this._selectedAction = 'skip';
    if (this._currentQuestion.type.indexOf('input') === -1) {
      this._buttons.focusActiveButton();
    }
  }

  _hideActionButton() {
    this._selectedAction = 'none';
  }

  /**
   * Aggregate widgets that signaled they're ready.
   *
   * Collect estimated 'tasks' count - widgets with more work
   * should return more 'tasks'.
   *
   * TODO: if it's already clear that this question will be skipped
   * because of `question.condition`, use `0` instead of `detail.tasks`.
   */
  _widgetReady(event) {
    // console.log('_widgetReady', event.detail.widget_id, this._currentWidget.id);

    if (event.detail.widget_id === '') {
      // eslint-disable-next-line no-console
      console.error('qset-questions: something fishy, widget with empty id!');
    }

    this._widgetTasks[event.detail.widget_id] = event.detail.tasks;
  }

  /**
   * Sum all mini-tasks estimated by the question widgets.
   */
  _sumTasks(tasks) {
    return Object.keys(tasks)
      .map(key => tasks[key])
      .reduce((a, b) => a + b);
  }

  /**
   * Mark tasks of widget identified by `widgetSlug` as completed.
   */
  _completeTasks(widgetSlug) {
    // this._widgetTasks[widgetSlug] = 0;
    this._widgetTasks[widgetSlug] = 1;

    this._qsetProgress = this._getProgress(this._widgetTasks);
  }

  /**
   * Some widget exited stage - remove it's mini-tasks from the queue.
   */
  _exitStage(e, detail) {
    this._completeTasks(detail.id);
  }

  /**
   * Calculates current progress as remaining tasks
   * divided by the sum of tasks calculated when the qset was created.
   *
   * TODO: to get a nice progress which is full at the last question, the last
   * question mustn't be included in the calculation...
   */
  _getProgress(tasks) {
    // avoid divison by 0
    // if (!this._totalTasks) return 0;
    const totalTasks = this.questions.length;
    const remainingTasks = this._sumTasks(tasks);

    // console.log('tasks', tasks, remainingTasks);

    // special case for last question, where we want 100%
    // if(remainingTasks == 1) return 100;

    // return Math.round((1 - (remainingTasks / this._totalTasks)) * 100);
    return Math.round((remainingTasks / totalTasks) * 100);
  }

  /**
   * Start the countdown timer for the current question via event `start-countdown`.
   * @param {object} event - Event object with `time` property.
   */
  _onStartCountdown(event) {
    // wait for the current transition to finish
    this._currentTransition.then(() => {
      this._card1.startCountdown(event.detail.time);
    });
  }

  _onCountdownEnded() {
    this._currentWidget.dispatchEvent(customEvent('countdown-ended'));
  }

  _onCountdownLastTick() {
    // hide the action button when the countdown timer is in last tick (500ms)
    // to prevent the user from clicking on the button before the countdown timer is ended
    // this eliminates the problem of the user clicking on the button and the countdown timer is firing a event at the same time
    // this solution is not perfect, but it's better than nothing
    // TODO: find a better solution
    this.dispatchEvent(customEvent('hide-action-button'));
  }

  // prevent clicks on cards during next widget images loading
  _preventClicks() {
    this._buttonsDisabled = true;
    this._card1Disabled = true;
  }

  // remove prevent clicks
  _enableClicks() {
    this._buttonsDisabled = false;
    this._card1Disabled = false;
  }

  // scroll to the bottom of this element
  async _scrollToBottom(top) {
    // wait for showing the action button
    await macrotask(0);

    // action buttons are positioned using sticky,
    // so we need to scroll to the bottom of this element on mobile screens
    window.scrollTo({
      top: top || this.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
}

window.customElements.define('qset-questions', QsetQuestions);
